import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  useSafeRelay,
} from 'utils/graphClient';
import {
  useVaultItemQuery,
  useVaultItemQuery$variables,
} from './__generated__/useVaultItemQuery.graphql';

export type RouteReference = PreloadedQuery<useVaultItemQuery>;

const VaultItemQuery = graphql`
  query useVaultItemQuery($vaultItemId: ID!) {
    node(id: $vaultItemId) {
      __typename
      ... on VaultItem {
        id
        vault {
          id
        }
      }
    }
  }
`;

export const useVaultItemQueryLoader = () => {
  return useQueryLoader<useVaultItemQuery>(VaultItemQuery);
};

export const usePreloadedVaultInfo = (routeRef: RouteReference) => {
  return usePreloadedQuery<useVaultItemQuery>(VaultItemQuery, routeRef);
};

export const useLazyLoadVaultItemQuery = (
  props: useVaultItemQuery$variables,
) => {
  const { useLazyLoadQuery } = useSafeRelay();
  return useLazyLoadQuery<useVaultItemQuery>(VaultItemQuery, props);
};
