import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

declare global {
  interface Window {
    cyHistory: { push: (s: string) => void };
    Cypress: any;
  }
}

export const CypressHistorySupport: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Cypress) {
      window.cyHistory = { push: navigate };
    }
  }, [navigate]);

  return null;
};
