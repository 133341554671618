import { Box, BoxProps, forwardRef } from '@cardboard-ui/react';
import React from 'react';

export interface ScreenProps
  extends Pick<
    BoxProps,
    | 'sx'
    | 'bg'
    | 'bgColor'
    | 'backgroundImage'
    | 'backgroundSize'
    | 'backgroundPosition'
  > {}

export const Screen = forwardRef<ScreenProps, 'div'>(
  ({ children, ...props }, ref) => (
    <Box
      ref={ref}
      h="100%"
      display="flex"
      flexDirection="column"
      overflow="hidden"
      w="100%"
      {...props}
    >
      <Box position="relative" flex="1" overflow="auto">
        {children}
      </Box>
    </Box>
  ),
);
