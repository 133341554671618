import {
  Box,
  Button,
  Center,
  forwardRef,
  HStack,
  Link,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { ArrowCircleRightIcon } from 'components/icons';
import React from 'react';
import { SIGN_IN_PATH } from 'utils/routes';
import { useSession } from 'utils/sessionProvider';
import { AuthenticationScreen } from '../AuthenticationScreen';
import { AuthenticationScreenHeading } from '../AuthenticationScreenHeading';

interface SignInFromSkeleton {
  invalidToken?: boolean;
}

const SignInFromSkeleton = forwardRef<SignInFromSkeleton, 'div'>(
  ({ invalidToken }, ref) => {
    const { tenant } = useSession();

    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>
            <HStack>
              <Text>{tenant ? t`Sign in to ${tenant.name}` : t`Sign in`}</Text>
            </HStack>
          </AuthenticationScreenHeading>
          <Box>
            {invalidToken ? (
              <InvalidToken />
            ) : (
              <Center>
                <Spinner />
              </Center>
            )}
          </Box>
        </Stack>
      </AuthenticationScreen>
    );
  },
);
export default SignInFromSkeleton;

const InvalidToken = () => (
  <VStack align="start">
    <Box>{t`This authentication link is no longer valid.`}</Box>
    <Button
      as={Link}
      rightIcon={<ArrowCircleRightIcon />}
      to={SIGN_IN_PATH}
      colorScheme="blue"
    >
      {t`Go to sign in`}
    </Button>
  </VStack>
);
