import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type UnreadChatMessagesContextType = {
  hasUnreadChatMessages: boolean;
  setUnreadChatMessages: (state: boolean) => void;
};

const initialUnreadChatMessagesContext: UnreadChatMessagesContextType = {
  hasUnreadChatMessages: false,
  setUnreadChatMessages: () => {},
};

export const useUnreadChatMessagesContext = () =>
  useContext(UnreadChatMessagesContext);

export const UnreadChatMessagesProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [hasUnreadChatMessages, setUnreadChatMessages] = useState(
    initialUnreadChatMessagesContext.hasUnreadChatMessages,
  );

  const providerState = {
    hasUnreadChatMessages,
    setUnreadChatMessages,
  };

  return (
    <UnreadChatMessagesContext.Provider value={providerState}>
      {children}
    </UnreadChatMessagesContext.Provider>
  );
};

const UnreadChatMessagesContext = createContext<UnreadChatMessagesContextType>(
  initialUnreadChatMessagesContext,
);
