import { useEffect } from 'react';
import { fromNavigator } from '@lingui/detect-locale';
import { Device } from '@capacitor/device';
import { useFeatureFlag, useSession } from '../sessionProvider';
import { AVAILABLE_LOCALES, AvailableLocale, DEFAULT_LOCALE } from '.';
import { notEmpty } from 'utils/NotEmptyFilter';
import { Capacitor } from '@capacitor/core';
import { useDeviceLanguagePreference } from './useDeviceLanguagePreference';

export const useInitialLanguage = () => {
  const { tenant, member } = useSession();
  const allowLanguages = useFeatureFlag('FEATURE_EXPERIMENTAL_LANGUAGES');
  const [deviceLanguagePreference, _] = useDeviceLanguagePreference();

  const tenantLanguageCode = tenant?.languageCode;
  const memberLanguageCode = member?.languageCode;
  const localeFromNavigator = fromNavigator();

  const isNativeApp = Capacitor.isNativePlatform();
  const shouldUseDeviceLanguage =
    allowLanguages && isNativeApp && deviceLanguagePreference;

  // Create a debugger function
  useEffect(() => {
    window.__debugLanguages = async () => {
      const capacitorLocale = (await Device.getLanguageTag()).value;
      // eslint-disable-next-line no-console
      console.debug({
        tenantLanguageCode,
        memberLanguageCode,
        localeFromNavigator,
        capacitorLocale,
        'navigator.language': navigator.language,
        'navigator.languages': navigator.languages,
        allowLanguages,
        isNativeApp,
        deviceLanguagePreference,
        shouldUseDeviceLanguage,
      });
    };
  }, [tenantLanguageCode, memberLanguageCode, deviceLanguagePreference]);

  // Our language selection logic
  //
  // if allowLanguages
  //   if native app
  //     if userWantToUseAccountLanguage
  //       Use account or network preferred language
  //       or DEFAULT_LANGUAGE
  //     else // user has given no option about what language to use
  //       use device language
  //       or Use account or network preferred language
  //       or DEFAULT_LANGUAGE
  //   else (browser)
  //     Use account or network preferred language
  //     or DEFAULT_LANGUAGE
  // else
  //  Use account or network preferred language
  //  or DEFAULT_LANGUAGE

  const getInitialLanguage = async () => {
    const languagesToConsider = [
      memberLanguageCode,
      tenantLanguageCode,
      DEFAULT_LOCALE,
    ];

    if (shouldUseDeviceLanguage) {
      const capacitorLocale = (await Device.getLanguageTag()).value;
      languagesToConsider.unshift(capacitorLocale);
    }
    const initialLanguage = selectAvailableLocale(languagesToConsider);

    console.debug('getInitialLanguage', {
      AVAILABLE_LOCALES,
      languagesToConsider,
      initialLanguage,
    });

    return initialLanguage;
  };

  return getInitialLanguage;
};

const selectAvailableLocale = (
  options: (string | undefined | null)[],
): AvailableLocale => {
  const detectedOption = options
    .filter(notEmpty)
    .flatMap((option) => [option, option.split(/[-_]/)[0]])
    .find((option) => AVAILABLE_LOCALES.includes(option)) as
    | AvailableLocale
    | undefined;

  return detectedOption || DEFAULT_LOCALE;
};
