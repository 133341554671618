import { useSessionStorage } from 'hooks/useBrowserStorage';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

export type SidecarContextType = {
  activeSidecarApp: 'NONE' | 'NOTIFICATIONS' | 'CHAT';
  setActiveSidecarApp: React.Dispatch<
    React.SetStateAction<SidecarContextType['activeSidecarApp']>
  >;
  closeSidecar: () => void;
  toggleActiveSidecarApp: (
    appName: SidecarContextType['activeSidecarApp'],
  ) => void;
};

const initialSidecarContext: SidecarContextType = {
  activeSidecarApp: 'NONE',
  setActiveSidecarApp: () => {},
  closeSidecar: () => {},
  toggleActiveSidecarApp: () => {},
};

export const useSidecarContext = () => useContext(SidecarContext);

export const SidecarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [activeSidecarApp, setActiveSidecarApp] = useSessionStorage<
    SidecarContextType['activeSidecarApp']
  >('ACTIVE_SIDECAR_APP', initialSidecarContext.activeSidecarApp);

  const providerState = {
    activeSidecarApp,
    setActiveSidecarApp,
    closeSidecar: () => setActiveSidecarApp('NONE'),
    toggleActiveSidecarApp: (
      appName: SidecarContextType['activeSidecarApp'],
    ) => {
      setActiveSidecarApp((s) => (s === appName ? 'NONE' : appName));
    },
  };

  return (
    <SidecarContext.Provider value={providerState}>
      {children}
    </SidecarContext.Provider>
  );
};

const SidecarContext = createContext<SidecarContextType>(initialSidecarContext);
