import {
  ComponentStyleConfig,
  useColorMode,
  useMultiStyleConfig,
} from '@cardboard-ui/react';
import { SettingWithColorModeVariants } from '../types';

export interface DesktopMenuSettingVariant {
  readonly backgroundColor: string;
  readonly textColor: string;
  readonly highlightColor: string;
  readonly highlightTextColor: string;
}
export type DesktopMenuSetting =
  SettingWithColorModeVariants<DesktopMenuSettingVariant>;

export const DefaultDesktopMenuStyle: DesktopMenuSetting = {
  light: {
    backgroundColor: '#2a4365',
    textColor: '#e2e8f0',
    highlightColor: '#2c5282',
    highlightTextColor: '#e2e8f0',
  },
  dark: {
    backgroundColor: '#2d3748',
    textColor: '#e2e8f0',
    highlightColor: '#4a5568',
    highlightTextColor: '#e2e8f0',
  },
};

export const DesktopMenuThemeKey = 'DesktopMenu';

export function useDesktopMenuStyle() {
  return useMultiStyleConfig(DesktopMenuThemeKey, {
    variant: useColorMode().colorMode,
  });
}

export function desktopMenuCustomSettingToStyleConfig(
  style: DesktopMenuSetting,
): ComponentStyleConfig {
  return {
    parts: ['menu', 'item', 'accountButton'],
    baseStyle: {
      menu: {
        borderRightWidth: '1px',
        flexDir: 'column',
        transition: 'width 0.3s',
      },
      item: {
        paddingStart: 2,
        paddingX: 2,
        paddingY: 1,
        borderRadius: 'md',
        transition: 'all 0.3s',
        fontWeight: 'medium',
        fontSize: 'sm',
      },
      accountButton: {
        borderTopWidth: '1px',
        flexWrap: 'nowrap',
        wordBreak: 'break-word',
        p: '2',
      },
    },
    variants: {
      light: {
        menu: {
          color: style.light.textColor,
          backgroundColor: style.light.backgroundColor,
        },
        item: {
          _hover: {
            backgroundColor: style.light.highlightColor,
            color: style.light.highlightTextColor,
          },
          _activeLink: {
            backgroundColor: style.light.highlightColor,
            color: style.light.highlightTextColor,
          },
        },
        accountButton: {
          borderTopColor: style.light.highlightColor,
        },
      },
      dark: {
        menu: {
          color: style.dark.textColor,
          backgroundColor: style.dark.backgroundColor,
        },
        item: {
          _hover: {
            backgroundColor: style.dark.highlightColor,
            color: style.dark.highlightTextColor,
            paddingStart: 3,
          },
          _activeLink: {
            backgroundColor: style.dark.highlightColor,
            color: style.dark.highlightTextColor,
          },
        },
        accountButton: {
          borderTopColor: style.dark.highlightColor,
        },
      },
    },
  };
}

export const DesktopMenu: ComponentStyleConfig =
  desktopMenuCustomSettingToStyleConfig(DefaultDesktopMenuStyle);
