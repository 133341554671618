import AwsS3 from '@uppy/aws-s3';
import Uppy, { UppyFile } from '@uppy/core';
import { MAX_UPLOAD_SIZE_IN_BYTES } from 'utils/constants';
import { authenticatedHttpRequest } from 'utils/http';
import { UploadContext } from 'utils/upload/useUppyAwsS3UploadParameters';

export const uppyFactory = ({
  uploadContext = 'FILES',
}: {
  uploadContext: UploadContext;
}) => {
  const uppy = new Uppy({
    restrictions: {
      maxFileSize: MAX_UPLOAD_SIZE_IN_BYTES,
    },
    debug: true,
  });

  uppy.use(AwsS3, {
    getUploadParameters: async (file: UppyFile) => {
      const { uploadToken, ...uploadParams } = await signUpload({
        uploadContext,
        file,
      });
      uppy.setFileState(file.id, {
        uploadToken,
      });
      return uploadParams;
    },
  });

  return uppy;
};

const signUpload = async ({
  uploadContext,
  file,
}: {
  uploadContext: UploadContext;
  file: UppyFile;
}) => {
  const response = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      query: /* GraphQL */ `
        mutation useUppyAwsS3UploadParametersMutation(
          $uploadContext: UploadContext!
          $fileName: String!
          $fileType: String!
          $fileSize: BigInt!
        ) {
          presignUpload(
            input: {
              uploadContext: $uploadContext
              fileName: $fileName
              fileType: $fileType
              fileSize: $fileSize
            }
          ) {
            uploadSignature {
              uploadToken
              signature {
                method
                url
                fields
                headers
              }
            }
          }
        }
      `,
      variables: {
        uploadContext,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
      },
    }),
  });
  const {
    data: { presignUpload },
  } = await response.json();
  const { signature, uploadToken } = presignUpload.uploadSignature;
  const { url: url_1, method, fields, headers } = signature;

  return {
    url: url_1 as string,
    method: method as 'PUT',
    uploadToken: uploadToken as string,
    fields: fields as Record<string, never>,
    headers: headers as Record<string, string>,
  };
};
