import { useCallback } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import {
  EVENT_ATTACHMENTS_PATH,
  SPACE_ATTACHMENTS_PATH,
  SPACE_EVENT_ATTACHMENTS_PATH,
  SPACES_PATH,
} from 'utils/routes';
import { useRouteQuery$data } from './__generated__/useRouteQuery.graphql';

type Node = NonNullable<useRouteQuery$data['node']>;

const useGeneratePath = () => {
  const pathname = useLocation().pathname;

  const generateFolderItemPath = useCallback(
    (item: Node) => {
      const space = item.ownershipSpace;
      const event = item.ownershipEvent;
      if (!space) {
        throw new Error(
          `FolderItem path requires an ownershipSpace. Item ID: ${item.id}`,
        );
      }

      const parentFolderId = (item.ownershipFolders?.nodes || [])[0]?.id;

      const params = new URLSearchParams({ i: item.id });
      if (parentFolderId) {
        params.set('folder', parentFolderId);
      }
      const paramsString = params.toString();

      if (event) {
        const spacePath = generatePath(SPACES_PATH, { spaceId: space.id });
        if (pathname.startsWith(spacePath)) {
          return generatePath(
            `${SPACE_EVENT_ATTACHMENTS_PATH}?${paramsString}`,
            { spaceId: space.id, eventId: event.id },
          );
        } else {
          return generatePath(`${EVENT_ATTACHMENTS_PATH}?${paramsString}`, {
            eventId: event.id,
          });
        }
      } else {
        return generatePath(`${SPACE_ATTACHMENTS_PATH}?${paramsString}`, {
          spaceId: space.id,
        });
      }
    },
    [pathname],
  );

  const generateFolderPath = useCallback(
    (folder: Node) => {
      const space = folder.ownershipSpace;
      const event = folder.ownershipEvent;
      if (!space) {
        throw new Error(
          `Folder path requires an ownershipSpace. Folder ID: ${folder.id}`,
        );
      }

      if (event) {
        const spacePath = generatePath(SPACES_PATH, { spaceId: space.id });
        if (pathname.startsWith(spacePath)) {
          return generatePath(
            `${SPACE_EVENT_ATTACHMENTS_PATH}?folder=${folder.id}`,
            { spaceId: space.id, eventId: event.id },
          );
        } else {
          return generatePath(`${EVENT_ATTACHMENTS_PATH}?folder=${folder.id}`, {
            eventId: event.id,
          });
        }
      } else {
        return generatePath(`${SPACE_ATTACHMENTS_PATH}?folder=${folder.id}`, {
          spaceId: space.id,
        });
      }
    },
    [pathname],
  );

  return {
    generateFolderItemPath,
    generateFolderPath,
  };
};

export default useGeneratePath;
