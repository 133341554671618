/* eslint-disable no-restricted-imports */
import {
  useColorMode as chakraUseColorMode,
  ColorMode,
} from '@chakra-ui/react';
import * as Sentry from '@sentry/react';

interface ColorModeContextType {
  colorMode: ColorMode;
  toggleColorMode: () => void;
  setColorMode: (value: any) => void;
}

type PossiblyInvalidColorMode = ColorMode | 'undefined' | undefined | string;
const DefaultColorMode = 'light' as ColorMode;

// We override the default Chakra color mode  behaviour as we detected
//   values for colorMode other than 'light' or 'dark' in production.
// `undefined` is the most common issue, and we no loner report it to Sentry, to avoid noise.
export const useColorMode = (): ColorModeContextType => {
  const { colorMode: _colorMode, ...rest } = chakraUseColorMode();
  // Here we set colorMode to `light` when it is undefined. (this can happen in production)
  // This prevents the fallback logic in the conditional below from running.
  //   and thus does not report `undefined` to Sentry.
  let colorMode: PossiblyInvalidColorMode = _colorMode || DefaultColorMode;

  // Here we set colorMode to `light` when it is the string "undefined". (this can happen in production)
  // This prevents the fallback logic in the conditional below from running.
  //   and thus does not report `undefined` to Sentry.
  if (colorMode === 'undefined') {
    colorMode = DefaultColorMode;
  }

  // This bit should never happen, but it can, so that is why we report to Sentry.
  if (!['light', 'dark'].includes(colorMode)) {
    colorMode = DefaultColorMode;

    Sentry.withScope((s) => {
      s.setLevel('warning');
      s.setTag('colorMode', _colorMode);
      Sentry.captureException(
        new Error(
          `Invalid color mode: ${
            JSON.stringify(_colorMode) || '`{undefined}`'
          }`,
        ),
      );
    });
  }

  return {
    colorMode: colorMode as ColorMode,
    ...rest,
  };
};
