/**
 * @generated SignedSource<<a8ff9410e86824988a4483d0778647a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useThemeEditing_theme$data = {
  readonly id: string;
  readonly variables: ReadonlyArray<{
    readonly colorDark?: HexColorString;
    readonly colorLight?: HexColorString;
    readonly imageDark?: {
      readonly id: string;
      readonly url: URIString;
    };
    readonly imageLight?: {
      readonly id: string;
      readonly url: URIString;
    };
    readonly name: string;
    readonly rawValue?: string;
  }>;
  readonly " $fragmentType": "useThemeEditing_theme";
};
export type useThemeEditing_theme$key = {
  readonly " $data"?: useThemeEditing_theme$data;
  readonly " $fragmentSpreads": FragmentRefs<"useThemeEditing_theme">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useThemeEditing_theme",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "variables",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "colorLight",
              "args": null,
              "kind": "ScalarField",
              "name": "light",
              "storageKey": null
            },
            {
              "alias": "colorDark",
              "args": null,
              "kind": "ScalarField",
              "name": "dark",
              "storageKey": null
            }
          ],
          "type": "Themes__Color",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rawValue",
              "storageKey": null
            }
          ],
          "type": "Themes__Content",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "imageLight",
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "light",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": "imageDark",
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "dark",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "type": "Themes__Image",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Themes__Theme",
  "abstractKey": null
};
})();

(node as any).hash = "de08eff0bcddce3eed526c33214c9747";

export default node;
