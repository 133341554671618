import React, { FC } from 'react';
import { Button, ButtonProps, forwardRef } from '@cardboard-ui/react';
import { AngleDownIcon, AngleUpIcon } from 'components/icons';
import {} from './Button';

export interface SpoilerButtonProps extends ButtonProps {
  isOpen: boolean;
}

export const SpoilerButton: FC<SpoilerButtonProps> = forwardRef<
  SpoilerButtonProps,
  'button'
>(({ isOpen, ...props }, ref) => (
  <Button
    ref={ref}
    colorScheme="gray"
    variant="link"
    size="md"
    rightIcon={isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
    {...props}
  />
));
