import React from 'react';
import { Box, ColorProps, forwardRef } from '@cardboard-ui/react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { FlipProp, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useI18n } from 'utils/i18n';

const Variants = Object.freeze({
  Normal: '16px',
  Large: '20px',
  XLarge: '24px',
  '2XLarge': '32px',
  '3XLarge': '48px',
});

export type IconVariant = keyof typeof Variants;
export interface CreateIconProps
  extends Omit<Omit<FontAwesomeIconProps, 'icon'>, 'color'> {
  key?: any;
}

export interface IconProps
  extends Omit<Omit<FontAwesomeIconProps, 'icon'>, 'color'>,
    Pick<ColorProps, 'color'> {
  key?: any;
  variant?: IconVariant;
}

export const createIcon = (
  icon: IconDefinition,
  createIconProps: CreateIconProps = {},
) => {
  return forwardRef<IconProps, 'svg'>(
    ({ variant, color, ...iconProps }, ref) => (
      <Box color={color} as="span" fontSize={variant && Variants[variant]}>
        <FontAwesomeIcon
          ref={ref}
          icon={icon}
          fixedWidth
          {...createIconProps}
          {...iconProps}
        />
      </Box>
    ),
  );
};

export const createDirectionalIcon = (
  iconLTR: IconDefinition,
  iconRTL?: IconDefinition,
  createIconProps: CreateIconProps = {},
) =>
  forwardRef<IconProps, 'svg'>(({ variant, color, ...iconProps }, ref) => {
    const { direction } = useI18n();
    const [icon, rtlProps] =
      direction === 'ltr'
        ? [iconLTR, {}]
        : iconRTL
        ? [iconRTL, {}]
        : [iconLTR, { flip: 'horizontal' as FlipProp }];
    return (
      <Box color={color} as="span" fontSize={variant && Variants[variant]}>
        <FontAwesomeIcon
          ref={ref}
          icon={icon}
          fixedWidth
          {...createIconProps}
          {...iconProps}
          {...rtlProps}
        />
      </Box>
    );
  });

export const createCombinedIcon = (
  config: Array<[IconDefinition, CreateIconProps]>,
) => {
  return forwardRef<IconProps, 'span'>(({ variant, color, ...props }, ref) => (
    <span ref={ref || undefined} className="fa-layers fa-1x fa-fw">
      {config.map(([icon, config], i) => (
        <Box
          key={icon.iconName}
          color={color}
          as="span"
          fontSize={variant && Variants[variant]}
        >
          <FontAwesomeIcon
            ref={ref}
            icon={icon}
            fixedWidth
            {...config}
            {...props}
          />
        </Box>
      ))}
    </span>
  ));
};
