import { useEffect } from 'react';
import { useColorMode, useColorModePreference } from '@cardboard-ui/react';
import { useCustomTheme } from './useCustomTheme';

export const ColorModeScript = () => {
  const { colorMode, setColorMode } = useColorMode();
  const systemColorMode = useColorModePreference();
  const { colorMode: persistedColorMode } = useCustomTheme();

  useEffect(() => {
    if (persistedColorMode === 'system') {
      setColorMode(systemColorMode);
    } else {
      setColorMode(persistedColorMode || colorMode);
    }
  }, [persistedColorMode, colorMode, systemColorMode]);

  return null;
};
