import React, {
  startTransition,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Container,
  forwardRef,
  StackDivider,
  useColorMode,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { Layout, useActiveLayout } from 'utils/LayoutProvider';
import { Screen } from 'layouts/Screen';
import { useCustomTheme } from 'apps/TenantApp/theme/CustomThemeProvider/useCustomTheme';
import { PublicScreenCustomContent } from 'apps/TenantApp/theme/components/PublicScreens';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { useAuthLogic } from '../../SignIn/utils/SignInLogic';
import { FORGOT_PASSWORD_PATH, SIGN_IN_PATH } from 'utils/routes';
import { useNavigate } from 'react-router-dom';

interface AuthenticationScreenProps {
  demoCustomContent?: PublicScreenCustomContent;
  returnToStart?: boolean;
}

export const AuthenticationScreen = forwardRef<
  AuthenticationScreenProps,
  'div'
>(({ children, returnToStart }, ref) => {
  const activeLayout = useActiveLayout();
  const isDesktop = activeLayout === Layout.DESKTOP;
  const { activeCustomTheme } = useCustomTheme();
  const { colorMode } = useColorMode();
  const bgColor = activeCustomTheme.publicScreens.background[colorMode].color;
  const bgImage =
    activeCustomTheme.publicScreens.background[colorMode].base64Image;
  const contentBg = useColorModeValue('white', 'gray.700');
  const [returningToSignIn, setReturningToSignIn] = useState(false);
  const { screen, setScreen } = useAuthLogic();
  const navigate = useNavigate();

  // We should navigate away from the rest password path, if we are on that one.
  // Ideally this should not be done here, but moving it to the correct location would require a lot of refactoring.
  useLayoutEffect(() => {
    if (
      screen.screen === 'start' &&
      returningToSignIn &&
      window.location.pathname.startsWith(FORGOT_PASSWORD_PATH)
    ) {
      navigate(SIGN_IN_PATH);
    }
  }, [screen.screen, returningToSignIn, navigate]);

  const handleReturn = useCallback(() => {
    startTransition(() => {
      setReturningToSignIn(true);
      setScreen({ screen: 'start' });
    });
  }, [setScreen, setReturningToSignIn]);

  return (
    <Screen
      bgColor={bgColor}
      backgroundImage={bgImage}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <Container
        minH={isDesktop ? undefined : '100%'}
        my={isDesktop ? 10 : 0}
        p={0}
        shadow="md"
        bg={contentBg}
        borderRadius={['none', 'none', 'base']}
        ref={ref}
        maxW="container.sm"
        display="flex"
        flexDirection="column"
      >
        <VStack
          spacing={0}
          divider={<StackDivider />}
          flex={1}
          alignItems="flex-start"
        >
          <Box flex={1} w="full" p={isDesktop ? 10 : 5}>
            {children}
          </Box>
          {returnToStart !== false && !isDesktop && (
            <Box w="full" py={4}>
              <Button
                variant="link"
                w="100%"
                leftIcon={<FontAwesomeIcon icon={light('chevron-left')} />}
                onClick={handleReturn}
              >{t`Start over`}</Button>
            </Box>
          )}
        </VStack>
      </Container>
      {returnToStart !== false && isDesktop && (
        <Box w="full">
          <Button
            variant="link"
            w="100%"
            leftIcon={<FontAwesomeIcon icon={light('chevron-left')} />}
            onClick={handleReturn}
          >{t`Start over`}</Button>
        </Box>
      )}
    </Screen>
  );
});
