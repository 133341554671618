import { useColorModeValue } from '@cardboard-ui/react';
import { Section } from 'components/Section';
import { SectionContent } from 'components/Section/SectionContent';
import { SectionHeader } from 'components/Section/SectionHeader';
import { Screen } from 'layouts/Screen';
import React, { FC, PropsWithChildren } from 'react';

interface ScreenWithSingleSectionProps {
  title?: string;
}

export const ScreenWithSingleSection: FC<
  PropsWithChildren<ScreenWithSingleSectionProps>
> = ({ children, title }) => {
  const screenBg = useColorModeValue('screen.light', 'screen.dark');

  return (
    <Screen bg={{ base: '', sm: screenBg }}>
      <Section my={20}>
        {title && (
          <>
            <SectionHeader title={title} />
          </>
        )}
        <SectionContent py={5}>{children}</SectionContent>
      </Section>
    </Screen>
  );
};
