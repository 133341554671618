import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Code,
} from '@cardboard-ui/react';
import { t, Trans } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function isHTMLAnchorElement(element: any): element is HTMLAnchorElement {
  return element instanceof HTMLElement && element.nodeName === 'A';
}

function rootDomainFromUrl(url: string): string {
  const appDomainParts = new URL(url).hostname.split('.').reverse();
  return `${appDomainParts[1]}.${appDomainParts[0]}`;
}

function isBlobOrDataUrl(url: string): boolean {
  return url.startsWith('blob:') || url.startsWith('data:');
}

// With the migration of the platfrom from govity.io to trustedfamily.com we overlooked bookmark links
// Making a fix in the front-end so we buy the back-end time to migrate
const legacyDomainRegExp = new RegExp(/^https:\/\/(?:[a-z0-9]+\.)*govity\.io/);

function isInternalHref(rawHref: string) {
  const href = rawHref.toLowerCase();
  return (
    rootDomainFromUrl(window.location.origin.toLowerCase()) ===
      rootDomainFromUrl(href) || legacyDomainRegExp.test(href)
  );
}

function isAcceptableExternalUrl(href: string) {
  return (
    href.startsWith('https://support.trustedfamily.net') ||
    href.startsWith('https://support.trustedfamily.com') ||
    href.startsWith('https://app.trustedfamily.com')
  );
}

declare global {
  interface Window {
    __handleLinkClick?: (
      event: MouseEvent | React.MouseEvent<Element, MouseEvent>,
    ) => void;
  }
}

export const LinkClickObserver = () => {
  const navigate = useNavigate();
  const [unHandledExternalUrl, setUnhandledExternalUrl] = useState<string>();
  const onClose = () => {
    setUnhandledExternalUrl(undefined);
  };

  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const openRef = React.useRef<any>(null);

  const onAnyClick = useCallback(
    (_event: MouseEvent | React.MouseEvent<Element, MouseEvent>) => {
      const event = Object.hasOwn(_event, 'nativeEvent')
        ? (_event as React.MouseEvent<Element, MouseEvent>).nativeEvent
        : (_event as MouseEvent);
      const handleLinkClick = ({ href, classList }: HTMLAnchorElement) => {
        if (openRef.current === event.target) {
          // If we are open, then the only possible click is a click on ourselves.
          // Lets allow that.
          return;
        }
        if (!href) {
          // If there is no value, lets have default things handle it
          return;
        }
        if (isBlobOrDataUrl(href)) {
          return;
        }
        if (isInternalHref(href)) {
          if (!classList.contains('chakra-link')) {
            const uri = new URL(href);
            navigate(uri.pathname + uri.search);
            event.preventDefault();
          }
          return;
        }
        if (isAcceptableExternalUrl(href)) {
          return;
        }

        event.preventDefault();
        setUnhandledExternalUrl(href);
      };

      if (event.target && event.target instanceof HTMLElement) {
        if (isHTMLAnchorElement(event.target)) {
          handleLinkClick(event.target);
        } else {
          const linkInPath = event.composedPath().find(isHTMLAnchorElement);
          if (linkInPath) {
            handleLinkClick(linkInPath);
          }
        }
      }
    },
    [],
  );

  // In dev mode the above onAnyClick may be changed, which will fail the
  //   removeEventListener call. This will lead to several copies of the handler
  //   being active at the same time.
  // To prevent this, we use a global variable, to keep track of the actual assigned function.
  //   which also doubles as the shortcut to open links from anywhere in the platform.
  useEffect(() => {
    document.addEventListener('click', onAnyClick, { capture: true });
    window.__handleLinkClick = onAnyClick;
    return () => {
      document.removeEventListener('click', onAnyClick, { capture: true });
      window.__handleLinkClick = undefined;
    };
  }, []);

  const externalUrl = <Code display="inline">{unHandledExternalUrl}</Code>;

  return (
    <AlertDialog
      isOpen={!!unHandledExternalUrl}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t`Leaving secure environment`}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Trans>
              You are about to leave the secure environment of the app and will
              be taken to {externalUrl}. Are you sure?
            </Trans>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t`I don't want to`}
            </Button>
            <Button
              as="a"
              ref={openRef}
              href={unHandledExternalUrl}
              target="_blank"
              colorScheme="red"
              onClick={onClose}
              ml={3}
            >
              {t`Take me there`}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
