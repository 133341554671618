import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { EVENTS_CALENDAR_MODE_PARAM } from '../../../utils/routes';
import { useLocalStorage } from '../../../hooks/useBrowserStorage';

type Mode = 'calendar' | 'list';
const EVENTS_VIEW_MODE_STORAGE_KEY = 'events_view_mode';

type CalendarViewContextProps = {
  mode: Mode;
  toggleMode: () => void;
};

export const CalendarViewContext = createContext<CalendarViewContextProps>({
  mode: 'list',
  toggleMode: () => {
    throw new Error('setMode requires a CalendarViewProvider');
  },
});

type CalendarViewProviderProps = {
  children: React.ReactNode;
};

export const CalendarViewProvider: React.FC<CalendarViewProviderProps> = ({
  children,
}) => {
  const [eventsViewMode, setEventsViewMode] = useLocalStorage<Mode>(
    EVENTS_VIEW_MODE_STORAGE_KEY,
    'list',
    'device',
  );

  // Copy the mode form the url into local settings, and get it over with
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchMode = searchParams.get(EVENTS_CALENDAR_MODE_PARAM) as Mode;
  useEffect(() => {
    if (urlSearchMode && urlSearchMode !== eventsViewMode) {
      setEventsViewMode(urlSearchMode);
      setSearchParams((oldParams) => {
        oldParams.delete(EVENTS_CALENDAR_MODE_PARAM);
        return oldParams;
      });
    }
  }, [urlSearchMode, setSearchParams, eventsViewMode, setEventsViewMode]);

  const toggleMode = useCallback(() => {
    setEventsViewMode((mode) => (mode === 'calendar' ? 'list' : 'calendar'));
  }, [setEventsViewMode]);

  return (
    <CalendarViewContext.Provider value={{ mode: eventsViewMode, toggleMode }}>
      {children}
    </CalendarViewContext.Provider>
  );
};

export const useCalendarViewMode = () => useContext(CalendarViewContext);
