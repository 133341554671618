import { useEffect, useRef } from 'react';

type When = boolean | (() => boolean);

const useEffectOnceWhen = (when: When, callback: () => void): void => {
  const shouldRun = typeof when === 'function' ? when() : when;
  const hasRunOnceRef = useRef(false);
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  });
  useEffect(() => {
    if (!hasRunOnceRef.current && shouldRun) {
      hasRunOnceRef.current = true;
      callbackRef.current();
    }
  }, [when]);
};

export default useEffectOnceWhen;
