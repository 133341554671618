import { useCallback, useState } from 'react';
import isRunningWithoutDomain from 'utils/isRunningWithoutDomain';
import { useSession } from 'utils/sessionProvider';

// Based on https://usehooks.com/useLocalStorage/

type BrowserStorageType = 'session' | 'local';
type BrowserStorageScope = 'account' | 'device';

function getStorage(
  storageType: BrowserStorageType,
): typeof window.sessionStorage {
  switch (storageType) {
    case 'local':
      return window.localStorage;
    default:
      return window.sessionStorage;
  }
}

export function useBrowserStorage<T>(
  key: string,
  initialValue: T,
  storageType: BrowserStorageType,
  scope: BrowserStorageScope,
  ttl?: number | undefined,
) {
  const scopedKey = useScopedKey(key, scope);

  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = getStorage(storageType).getItem(scopedKey);
      if (!item) return initialValue;
      // This is required to support legacy values that were stored before this hook was introduced, which might be stored as a non-strinigified string
      // Example: locale is historically stored as "en" instead of "'en'", which causes JSON.parse to fail.
      const parsedValue = JSON.parse(item);

      if (
        parsedValue &&
        typeof parsedValue === 'object' &&
        '__ttl' in parsedValue &&
        'expires' in parsedValue &&
        'value' in parsedValue
      ) {
        return parsedValue.expires > Date.now()
          ? parsedValue.value
          : initialValue;
      } else {
        return parsedValue;
      }
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((val: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        if (ttl) {
          getStorage(storageType).setItem(
            scopedKey,
            JSON.stringify({
              value: valueToStore,
              expires: Date.now() + ttl,
              __ttl: true,
            }),
          );
        } else {
          getStorage(storageType).setItem(
            scopedKey,
            JSON.stringify(valueToStore),
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    [storedValue, storageType, scopedKey],
  );

  const removeEntry = useCallback(() => {
    try {
      getStorage(storageType).removeItem(scopedKey);
    } catch (error) {
      console.error(error);
    }
  }, [storageType, scopedKey]);
  return [storedValue, setValue, removeEntry] as const;
}

export function useSessionStorage<T>(
  key: string,
  initialValue: T,
  scope: BrowserStorageScope = 'account',
) {
  return useBrowserStorage(key, initialValue, 'session', scope, undefined);
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  scope: BrowserStorageScope = 'account',
) {
  return useBrowserStorage(key, initialValue, 'local', scope, undefined);
}

export function useExpiringLocalStorage<T>(
  key: string,
  initialValue: T,
  scope: BrowserStorageScope = 'account',
  ttl: number,
) {
  return useBrowserStorage(key, initialValue, 'local', scope, ttl);
}

const useScopedKey = (key: string, scope: BrowserStorageScope) => {
  const { member } = useSession();

  if (scope === 'device' || !isRunningWithoutDomain()) {
    return key;
  }

  if (!member) {
    throw new Error(
      `Can not fetch account scoped data, without account. (${key})`,
    );
  }
  return `${member.id}-${key}`;
};
