import React, { useEffect, useState } from 'react';
import { authenticatedHttpRequest } from 'utils/http';
import { useSession } from 'utils/sessionProvider';
import { TOKEN_PARAM } from 'utils/routes';
import useSafeParam from 'utils/routes/useSafeParam';
import AlreadySignedIn from '../AlreadySignedIn';
import SignInFromSkeleton from '../SignInFromSkeleton';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';

export const SignInFromGlobalEmailToken = () => {
  const token = useSafeParam(TOKEN_PARAM);
  const { authenticate, isAuthenticated } = useSession();
  const [invalidToken, setInvalidToken] = useState(false);
  const [returnToPath] = usePersistedReturnToPath();

  useEffect(() => {
    if (!isAuthenticated) {
      authenticatedHttpRequest('/auth/global-email-auth', {
        method: 'POST',
        body: JSON.stringify({
          key: token,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          authenticate();
        } else {
          setInvalidToken(true);
        }
      });
    }
  }, [token, isAuthenticated, authenticate]);

  if (isAuthenticated) {
    return <AlreadySignedIn returnToPath={returnToPath} />;
  }

  return <SignInFromSkeleton invalidToken={invalidToken} />;
};

export default SignInFromGlobalEmailToken;
