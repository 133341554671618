import { registerPlugin } from '@capacitor/core';

const Downloader = registerPlugin<DownloaderPlugin>('Downloader', {});

interface DownloadFileOptions {
  url: string;
  fileName: string;
}

export interface DownloaderPlugin {
  downloadFile(options: DownloadFileOptions): Promise<{ path: string | null }>;
}

export { Downloader };
