import { Stack, StackDivider, StackProps } from '@cardboard-ui/react';
import React from 'react';

interface ListProps extends StackProps {
  withDivider?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const List: React.FC<ListProps> = ({
  children,
  withDivider,
  size,
  ...props
}) => {
  let spacing;
  switch (size) {
    case 'lg':
      spacing = { base: 2, sm: 6 };
      break;
    case 'md':
      spacing = { base: 1, sm: 3 };
      break;
    case 'sm':
    default:
      spacing = 0;
  }
  return (
    <Stack
      spacing={spacing}
      py={spacing}
      divider={withDivider ? <StackDivider /> : undefined}
      {...props}
    >
      {children}
    </Stack>
  );
};
