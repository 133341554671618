/* eslint-disable no-restricted-imports */

import {
  MenuItemOption as ChakraMenuItemOption,
  Checkbox,
  forwardRef,
  HStack,
  MenuItemOptionProps,
} from '@chakra-ui/react';
import React from 'react';

export const MenuItemOption = forwardRef<MenuItemOptionProps, 'button'>(
  ({ children, isChecked, icon, onClick, ...props }, ref) => (
    <ChakraMenuItemOption
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick?.(e);
      }}
      icon={<></>}
      {...props}
    >
      <HStack>
        <Checkbox isChecked={isChecked} size="lg" />
        {children}
      </HStack>
    </ChakraMenuItemOption>
  ),
);

MenuItemOption.id = 'MenuItemOption';

MenuItemOption.displayName = 'MenuItemOption';
