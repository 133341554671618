import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ChakraProvider, useToast } from '@cardboard-ui/react';
import TenantApp from 'apps/TenantApp';
import SignOut from 'apps/TenantApp/screens/authentication/SignOut';
import { useNetworkStatus } from 'hooks/useNetworkStatus';
import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { AppCrashed } from 'screens/AppCrashed';
import { CUSTOM_THEME } from 'theme';
import { CypressHistorySupport } from 'utils/CypressHistorySupport';
import { ErrorBoundary } from 'utils/errors';
import { clearNativeCsrfToken } from 'utils/getCsrfToken';
import { DemoI18nProvider as MiniI18nProvider } from 'utils/i18n';
import { LayoutProvider } from 'utils/LayoutProvider';
import {
  defaultTenantLandingPath,
  SETUP_TWO_FACTOR_PATH,
  SIGN_IN_PATH,
  SIGN_OUT_PATH,
  SIGN_UP_PATH,
} from 'utils/routes';
import './base-layout.css';
import { MotionConfig } from 'framer-motion';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';
import { useSession } from './utils/sessionProvider';

declare global {
  interface Window {
    maintenance?: boolean;
  }
}

class MaintenanceError extends Error {
  maintenance: boolean;

  constructor() {
    super('Maintenance Mode');
    this.maintenance = true;
  }
}

class NetworkDisconnectedError extends Error {
  disconnected: boolean;

  constructor() {
    super('Network Disconnected');
    this.disconnected = true;
  }
}

const WindowMaintenanceDetection = () => {
  if (window.maintenance) {
    throw new MaintenanceError();
  }
  return null;
};

const NETWORK_DISCONNECTED_ID = 'network_disconnected';
const NetworkStatusDetection = () => {
  const networkStatus = useNetworkStatus();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    if (networkStatus?.connected === false) {
      toast({
        id: NETWORK_DISCONNECTED_ID,
        title: 'Network Disconnected',
        description: 'Please check your internet connection.',
        status: 'info',
        duration: null,
        isClosable: false,
      });
    } else {
      toast.close(NETWORK_DISCONNECTED_ID);
    }
  }, [networkStatus]);

  useEffect(() => {
    if (networkStatus?.connected === false) {
      throw new NetworkDisconnectedError();
    }
  }, [location]);

  return null;
};

const Root = () => (
  <MotionConfig reducedMotion={window.Cypress ? 'always' : 'user'}>
    <MobileAppStateListeners />
    <NavigationHacks />
    <UploadFolderSupportDetection />
    <CypressHistorySupport />
    <ErrorBoundary fallback={AppCrashed}>
      <WindowMaintenanceDetection />
      <NetworkStatusDetection />
      <Routes>
        <Route path="__preload" element={<E2ETestPreloader />} />
        <Route path="__unload" element={<E2ETestUnloader />} />
        <Route path={SIGN_OUT_PATH} element={<SignOutAsAnApp />} />
        <Route path="*" element={<TenantApp />} />
      </Routes>
    </ErrorBoundary>
  </MotionConfig>
);

const router = createBrowserRouter(
  createRoutesFromElements(<Route path="/*" element={<Root />} />),
);

const PreApp = () => <RouterProvider router={router} />;

const SignOutAsAnApp = () => (
  <ChakraProvider theme={CUSTOM_THEME} resetCSS>
    <LayoutProvider>
      <MiniI18nProvider locale="en">
        <SignOut />
      </MiniI18nProvider>
    </LayoutProvider>
  </ChakraProvider>
);

const MobileAppStateListeners = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      App.addListener('pause', () => {
        clearNativeCsrfToken();
      });
    }
  }, []);

  return null;
};

const E2ETestPreloader = () => {
  return <div>Test preloader</div>;
};

const E2ETestUnloader = () => {
  return <div>Test unloader</div>;
};

declare global {
  interface Window {
    __goToTwoFactorScreen: () => void;
    __goToTwoFactorSetupScreen: () => void;
    __goToHomeScreen: () => void;
  }
}

const NavigationHacks = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const hasSearchParams = params.toString().length !== 0;
  const [returnPath, persistReturnToPath] = usePersistedReturnToPath();
  const { isGuest } = useSession();

  useEffect(() => {
    window.__goToHomeScreen = () => {
      const landingPath = defaultTenantLandingPath(isGuest);
      if (hasSearchParams || window.location.pathname !== landingPath) {
        navigate({
          pathname: landingPath,
          search: '',
        });
      }
    };
    window.__goToTwoFactorSetupScreen = () => {
      if (window.location.pathname !== SETUP_TWO_FACTOR_PATH) {
        if (
          !(
            window.location.pathname.startsWith(SIGN_IN_PATH) ||
            window.location.pathname.startsWith(SIGN_UP_PATH)
          )
        ) {
          const returnToPathFromLocation = window.location.href.replace(
            window.location.origin,
            '',
          );
          persistReturnToPath(returnToPathFromLocation);
        }
        navigate(SETUP_TWO_FACTOR_PATH);
      }
    };
  }, [navigate]);

  return null;
};

declare global {
  interface Window {
    __isUploadFolderSupported: boolean;
  }
}

const UploadFolderSupportDetection = () => {
  useEffect(() => {
    // webkitdirectory is not supported in mobile browsers https://caniuse.com/input-file-directory
    window.__isUploadFolderSupported =
      'webkitdirectory' in document.createElement('input');
  }, []);

  return null;
};

export default PreApp;
