import React, { useCallback } from 'react';
import { useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { SignInWithEmailTokenRedesigned } from '../../redesigned/SignInWithEmailTokenRedesigned';
import { t } from '@lingui/macro';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

export const SignInViaMailPinScreen = ({
  login,
  token,
}: {
  login: string;
  token: string;
}) => {
  const { onEmailSignInWithPin, setScreen } = useAuthLogic();
  const [pinInFlight, setPinInFlight] = React.useState(false);

  const toast = useToast();

  const onCodeValidated = useCallback(
    (pin: string) => {
      if (pinInFlight) {
        return;
      }
      setPinInFlight(true);
      onEmailSignInWithPin({ pin, token })
        .then(({ accounts }) => {
          if (accounts.length > 0) {
            setScreen({
              screen: 'pick-tenant',
              accounts: accounts!,
            });
          }
        })
        .catch(() => {
          toast({
            title: t`The pin provided is not correct`,
            status: 'error',
            description: t`If this error persists, try requesting the sign-in email again.`,
          });
        })
        .finally(() => {
          setPinInFlight(false);
        });
    },
    [onEmailSignInWithPin, setScreen, token, toast, pinInFlight],
  );

  return (
    <SignInWithEmailTokenRedesigned
      email={login}
      onCodeValidated={onCodeValidated}
    />
  );
};
