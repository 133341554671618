import {
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  AlertDialog as ChakraAlertDialog,
  VStack,
} from '@cardboard-ui/react';
import { FocusableElement } from '@cardboard-ui/utils';
import { OverlayProps } from 'components/TaskOverlay';
import React, { FC } from 'react';

interface AlertDialogProps extends OverlayProps {
  title: string;
  cancelButton?: AlertDialogButtonProps;
  actionButton?: AlertDialogButtonProps;
  leastDestructiveRef?: React.RefObject<FocusableElement>; // If the dialog requires input, we shouldn't autofocus on the least destructive ref
  'data-qa'?: string;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
}

interface AlertDialogButtonProps {
  onClick(): void;
  title: string;
  isDestructive?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  ariaLabel?: string;
  colorScheme?: string;
}

export const AlertDialog: FC<AlertDialogProps> = ({
  title,
  cancelButton,
  actionButton,
  leastDestructiveRef,
  children,
  isOpen,
  onClose,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  ...props
}) => {
  const cancelRef = React.useRef(null);
  return (
    <ChakraAlertDialog
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      leastDestructiveRef={leastDestructiveRef || cancelRef}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <AlertDialogOverlay>
        <AlertDialogContent mx="20px" data-qa={props['data-qa']}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          {children && <AlertDialogBody>{children}</AlertDialogBody>}

          <AlertDialogFooter>
            <VStack spacing={2} width="100%">
              {actionButton && (
                <Button
                  width="100%"
                  colorScheme={
                    actionButton.isDestructive
                      ? 'red'
                      : actionButton.colorScheme || 'blue'
                  }
                  onClick={actionButton.onClick}
                  aria-label={actionButton.ariaLabel}
                  isDisabled={actionButton.isDisabled}
                  isLoading={actionButton.isLoading}
                >
                  {actionButton.title}
                </Button>
              )}
              {cancelButton && (
                <Button
                  width="100%"
                  ref={cancelRef}
                  onClick={cancelButton.onClick}
                  isLoading={cancelButton.isLoading}
                  isDisabled={cancelButton.isDisabled}
                >
                  {cancelButton.title}
                </Button>
              )}
            </VStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};
