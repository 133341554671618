import { useSession } from 'utils/sessionProvider';
import SignInWithTenant from './components/SignInWithTenant';
import SignInWithoutTenant from './components/SignInWithoutTenant';
import React from 'react';
import NoTenant from '../../../NoTenant';
import isRunningWithoutDomain from 'utils/isRunningWithoutDomain';
import { Capacitor } from '@capacitor/core';

export const LegacySignIn = () => {
  const { tenant } = useSession();

  if (tenant && !Capacitor.isNativePlatform()) {
    return <SignInWithTenant />;
  }

  if (allowNonTenantAuthentication()) {
    return <SignInWithoutTenant />;
  }

  return <NoTenant />;
};

export default LegacySignIn;

export const allowNonTenantAuthentication = isRunningWithoutDomain;
