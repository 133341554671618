declare global {
  const REACT_APP_ASSETS_DOMAIN: string | null;

  // PDFTron config files SHA1
  const PDFTRON_CONFIG_BASE_SHA1: string | null;
  const PDFTRON_CONFIG_SIGN_SHA1: string | null;
  const PDFTRON_CONFIG_CREATE_SIGN_SHA1: string | null;
  const PDFTRON_LICENCE: string | null;

  // Dropbox App Key
  const DROPBOX_APP_KEY: string | null;

  // Unleash
  const UNLEASH_URL: string | null;
  const UNLEASH_API_TOKEN: string | null;
}

export function useCardboardEnv() {
  return {
    REACT_APP_ASSETS_DOMAIN: REACT_APP_ASSETS_DOMAIN,
    PDFTRON_CONFIG_BASE_SHA1: PDFTRON_CONFIG_BASE_SHA1,
    PDFTRON_CONFIG_SIGN_SHA1: PDFTRON_CONFIG_SIGN_SHA1,
    PDFTRON_CONFIG_CREATE_SIGN_SHA1: PDFTRON_CONFIG_CREATE_SIGN_SHA1,
    PDFTRON_LICENCE: PDFTRON_LICENCE,
    DROPBOX_APP_KEY: DROPBOX_APP_KEY,
    UNLEASH_URL: UNLEASH_URL,
    UNLEASH_API_TOKEN: UNLEASH_API_TOKEN,
  };
}
