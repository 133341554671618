import { Capacitor } from '@capacitor/core';
import { useToast, UseToastOptions } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { createClient as createWSClient, Client as WSClient } from 'graphql-ws';
import {
  createClient as createSSEClient,
  Client as SSEClient,
} from 'graphql-sse';
import React, { PropsWithChildren, useMemo } from 'react';
import { RelayEnvironmentProvider } from 'utils/graphClient';
import {
  Environment,
  Network,
  Observable,
  OperationDescriptor,
  PayloadData,
  RecordSource,
  RequestParameters,
  Store,
  SubscribeFunction,
  Variables,
} from 'relay-runtime';
import { authenticatedHttpRequest, HttpResponse } from 'utils/http';
import { twoFactorState } from './provider';

let globalStore = new Store(new RecordSource({}));

export const RelayProvider: React.FC<
  PropsWithChildren<{
    initialPayload?: InitialPayloadType;
  }>
> = ({ children, initialPayload }) => {
  const toast = useToast();

  const onFetchError = buildFetchErrorHandler({ toast });

  globalStore = new Store(new RecordSource({}));

  const environment = getEnvironment({
    name: window.location.hostname,
    tenantDomain: window.location.hostname,
    onFetchError,
    initialPayload,
    toast,
  });

  return (
    <RelayEnvironmentProvider environment={environment} children={children} />
  );
};

export const useExportEnvironment = ({ name }: { name: string }) => {
  const toast = useToast();

  const env = useMemo(() => {
    const onFetchError = buildFetchErrorHandler({ toast });

    return getEnvironment({
      name,
      tenantDomain: window.location.hostname,
      onFetchError,
      toast,
      store: new Store(new RecordSource({})),
    });
  }, [name]);

  return env;
};

export type InitialPayloadType = {
  operationDescriptor: OperationDescriptor;
  payload: PayloadData;
};

interface RelayEnvironmentInput {
  tenantDomain?: string;
  onFetchError?: (e: GraphQlResponseError) => void;
  initialPayload?: InitialPayloadType;
}

interface NamedRelayEnvironmentInput extends RelayEnvironmentInput {
  name: string;
  store?: Store;
  subscriptionsProtocol?: 'ws' | 'sse';

  // Toast is optional, but if it's not provided, we'll log the error to the console
  // This is mainly for backwards compatibility
  toast?: (options: UseToastOptions) => void;
}

const getSubscriptionsClient = (
  subscriptionsProtocol: 'ws' | 'sse',
  options: { toast?: (options: UseToastOptions) => void } = {},
): WSClient | SSEClient => {
  const clients = {
    ws: createWSClient({
      url: `wss://${window.location.hostname}/chat-graph-subscriptions`,
      keepAlive: 30_000,
      retryAttempts: Infinity,
      lazy: true,
      retryWait: async () => {
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 + Math.random() * 3000),
        );
      },
      shouldRetry: (error) => {
        return !!error;
      },
      on: {
        closed: () => {
          const { toast } = options;

          if (toast) {
            toast({
              id: 'ws_closed',
              title: t`Connection to the chat server is lost`,
              description: t`You can try to refresh the page to reconnect`,
              status: 'error',
              duration: null,
              isClosable: true,
            });
          } else {
            // eslint-disable-next-line no-console
            console.error('WS closed');
          }
        },
      },
    }),
    sse: createSSEClient({
      url: `https://${window.location.hostname}/chat-graph`,
      credentials: 'include',
      retryAttempts: Infinity,
    }),
  };

  return clients[subscriptionsProtocol];
};

export const getEnvironment = ({
  name,
  tenantDomain,
  onFetchError,
  initialPayload,
  toast,
  store = globalStore,
  subscriptionsProtocol = 'ws',
}: NamedRelayEnvironmentInput) => {
  const client = getSubscriptionsClient(subscriptionsProtocol, { toast });

  const subscribe = (operation: RequestParameters, variables: Variables) => {
    return Observable.create((sink) => {
      return client.subscribe(
        {
          operationName: operation.name,
          query: operation.text || '',
          variables,
        },
        sink,
      );
    });
  };

  const env = new Environment({
    configName: name,
    treatMissingFieldsAsNull: true, // When (via directives) part of the tree is not returned, to fret, just move on.
    network: Network.create(
      buildFetchQuery({ tenantDomain, onFetchError }),
      subscribe as SubscribeFunction,
    ),
    store,
  });

  if (initialPayload) {
    env.commitPayload(
      initialPayload.operationDescriptor,
      initialPayload.payload,
    );
  }

  return env;
};

class GraphQlRequiresTwoFactor extends Error {
  constructor() {
    super('Graphql require two factor auth');
  }
}

class GraphQlResponseError extends Error {
  response: HttpResponse;

  constructor(response: HttpResponse) {
    super(`Invalid graphql response. Status: "${response.status}"`);
    this.response = response;
  }
}

const buildFetchQuery =
  ({ tenantDomain, onFetchError }: RelayEnvironmentInput) =>
  (request: RequestParameters, variables: Variables) => {
    const url = request.text?.includes('_chat_')
      ? `https://${window.location.hostname}/chat-graph`
      : `https://${window.location.hostname}/graph`;

    return authenticatedHttpRequest(
      url,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify({
          query: request.text,
          variables,
        }),
      },
      5, // retry 5 times if call fails
    ).then(buildHandleQueryResponse({ onFetchError }));
  };

const buildHandleQueryResponse =
  ({ onFetchError }: Omit<RelayEnvironmentInput, 'tenantDomain'>) =>
  (response: HttpResponse) => {
    if (response.needsTwoFactorAuth) {
      twoFactorState.getState().requiresTwoFactor();
      return response.json();
    } else if (response.needsTwoFactorSetup) {
      window.__goToTwoFactorSetupScreen();
      return response.json();
    } else if (response.status === 200) {
      return response.json();
    } else {
      const error = new GraphQlResponseError(response);
      onFetchError && onFetchError(error);
      throw error;
    }
  };

interface MaybeGraphQlResponseError {
  response?: HttpResponse;
}

interface FetchErrorHandlerProps {
  toast: ReturnType<typeof useToast>;
}

const SERVER_ERROR_TOAST_ID = 'server_error';
const MAINTENANCE_ERROR_TOAST_ID = 'maintenance_mode';

const buildFetchErrorHandler =
  ({ toast }: FetchErrorHandlerProps) =>
  (e: MaybeGraphQlResponseError) => {
    if (e.response && isMaintenaceResponse(e.response)) {
      if (!toast.isActive(MAINTENANCE_ERROR_TOAST_ID)) {
        toast({
          id: MAINTENANCE_ERROR_TOAST_ID,
          title: t`Maintenance Mode`,
          description: t`The application has gone into maintenance mode`,
          status: 'error',
          duration: null,
          isClosable: true,
          onCloseComplete: () => {
            // eslint-disable-next-line no-restricted-properties
            if (!Capacitor.isNativePlatform()) window.location.reload();
          },
        });
      }
    } else {
      if (!toast.isActive(SERVER_ERROR_TOAST_ID)) {
        toast({
          id: SERVER_ERROR_TOAST_ID,
          title: t`Server error`,
          status: 'error',
          isClosable: true,
        });
      }
    }
  };

const isMaintenaceResponse = (response: HttpResponse) =>
  response.status === 503 &&
  response.headers['x-cardboard-unavailable'] === 'maintenance';
