import { graphql, useMutation, useSafeRelay } from 'utils/graphClient';
import { ErrorHandlingMutationConfig } from 'utils/graphClient/graph';
import { useThemeEditing_addAttachmentsToTheme_Mutation } from './__generated__/useThemeEditing_addAttachmentsToTheme_Mutation.graphql';
import { useThemeEditing_theme$data } from './__generated__/useThemeEditing_theme.graphql';
import {
  useThemeEditing_theme_Query,
  useThemeEditing_theme_Query$data,
} from './__generated__/useThemeEditing_theme_Query.graphql';
import { useThemeEditing_updateTheme_Mutation } from './__generated__/useThemeEditing_updateTheme_Mutation.graphql';

const fragment = graphql`
  fragment useThemeEditing_theme on Themes__Theme {
    id
    variables {
      name
      ... on Themes__Color {
        colorLight: light
        colorDark: dark
      }
      ... on Themes__Content {
        rawValue
      }
      ... on Themes__Image {
        imageLight: light {
          id
          url
        }
        imageDark: dark {
          id
          url
        }
      }
    }
  }
`;
const query = graphql`
  query useThemeEditing_theme_Query {
    sessionInfo {
      id
      tenant {
        id
        theme {
          id
          variables {
            name
            ... on Themes__Color {
              colorLight: light
              colorDark: dark
            }
            ... on Themes__Content {
              rawValue
            }
            ... on Themes__Image {
              imageLight: light {
                id
                url
              }
              imageDark: dark {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`;

const updateThemeMutation = graphql`
  mutation useThemeEditing_updateTheme_Mutation(
    $themeId: ID!
    $variables: [Themes__VariableInput!]!
  ) {
    updateTheme(input: { id: $themeId, variables: $variables }) {
      themeUpdated {
        id
        ...useThemeEditing_theme
      }
    }
  }
`;

const addAttachmentsMutation = graphql`
  mutation useThemeEditing_addAttachmentsToTheme_Mutation(
    $themeId: ID!
    $attachments: [FileInput!]!
  ) {
    addAttachmentsToTheme(
      input: { themeId: $themeId, attachments: $attachments }
    ) {
      themeUpdated {
        id
        ...useThemeEditing_theme
      }
      attachmentsAdded {
        __typename
        id
        name
        url
        size
        mimeType
      }
    }
  }
`;

interface useThemeEditing {
  isUpdating: boolean;
  updateTheme(
    config: ErrorHandlingMutationConfig<useThemeEditing_updateTheme_Mutation>,
  ): void;
  theme: NonNullable<
    useThemeEditing_theme_Query$data['sessionInfo']['tenant']
  >['theme'];
  getThemeVariable(
    variableKey: string,
  ): useThemeEditing_theme$data['variables'][0] | undefined;

  isAddingAttachments: boolean;
  addAttachmentsToTheme(
    config: ErrorHandlingMutationConfig<useThemeEditing_addAttachmentsToTheme_Mutation>,
  ): void;
}

export function useThemeEditing(): useThemeEditing {
  const { useLazyLoadQuery } = useSafeRelay();
  const { sessionInfo } = useLazyLoadQuery<useThemeEditing_theme_Query>(
    query,
    {},
  );

  const [updateTheme, isUpdating] =
    useMutation<useThemeEditing_updateTheme_Mutation>(updateThemeMutation);

  const [addAttachmentsToTheme, isAddingAttachments] =
    useMutation<useThemeEditing_addAttachmentsToTheme_Mutation>(
      addAttachmentsMutation,
    );

  const theme = sessionInfo.tenant?.theme;

  return {
    isUpdating: isUpdating,
    updateTheme: (
      config: ErrorHandlingMutationConfig<useThemeEditing_updateTheme_Mutation>,
    ) => {
      updateTheme(config);
    },
    theme: theme || { id: 'invalidTheme', variables: [] },
    getThemeVariable: (variableKey) =>
      theme?.variables.find((v) => v.name === variableKey),
    isAddingAttachments,
    addAttachmentsToTheme,
  };
}
