import { Box } from '@cardboard-ui/react';
import * as React from 'react';

export const TrustedFamilyLogo = () => (
  <Box>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      width={50}
      x={0}
      y={0}
      viewBox="0 0 1024 1024"
    >
      <style>{'.st0{fill:#00c587}.st1{fill:#00aae7}'}</style>
      <path
        d="M259.54 721.44c-4.3 0-8.66-1.15-12.59-3.56-42.24-25.9-67.29-64.4-70.56-108.41-3.06-41.21 14.64-82.19 47.33-109.63 47.87-40.17 65.69-114.25 65.87-115 3.04-12.98 16.04-21.03 29.01-17.98 12.98 3.04 21.03 16.03 17.98 29.01-.86 3.67-21.8 90.56-81.83 140.94-21.15 17.75-32.16 42.93-30.22 69.09 2.08 28.11 19.01 53.26 47.66 70.83 11.36 6.97 14.92 21.83 7.96 33.19-4.56 7.42-12.49 11.52-20.61 11.52z"
        className="st0"
      />
      <path
        d="M429.41 850.97c-33.05 0-63.21-10.74-87.36-31.55-31.3-26.98-47.77-68.47-44.05-110.99 5.41-61.86-34.36-127.27-34.76-127.92-6.98-11.34-3.47-26.2 7.86-33.2 11.33-7 26.17-3.52 33.19 7.8 1.99 3.21 48.63 79.45 41.8 157.52-2.41 27.51 7.61 53.1 27.48 70.23 21.35 18.4 51.11 24.22 83.78 16.38 12.95-3.1 25.99 4.88 29.1 17.84 3.11 12.96-4.88 25.99-17.84 29.1-13.38 3.2-26.51 4.79-39.2 4.79z"
        className="st1"
      />
      <path
        d="M598.45 850.99c-38.04 0-75.04-17.41-100.44-47.67-39.92-47.57-114.29-65.7-115.03-65.88-12.95-3.08-20.98-16.07-17.92-29.03 3.06-12.96 16.02-20.99 28.98-17.95 3.67.86 90.56 21.8 140.94 81.83 17.75 21.15 42.94 32.15 69.09 30.22 28.11-2.08 53.26-19.01 70.83-47.66 6.97-11.36 21.82-14.92 33.19-7.96 11.36 6.97 14.92 21.83 7.96 33.19-25.9 42.24-64.4 67.29-108.4 70.56-3.07.24-6.14.35-9.2.35z"
        className="st0"
      />
      <path
        d="M565.98 767.25c-8.09 0-15.99-4.06-20.56-11.44-7-11.33-3.52-26.17 7.8-33.19 3.21-1.99 79.46-48.63 157.52-41.8 27.51 2.41 53.1-7.61 70.22-27.48 18.4-21.35 24.22-51.1 16.38-83.78-3.11-12.96 4.88-25.99 17.84-29.1 12.95-3.1 25.99 4.88 29.1 17.84 11.55 48.18 2.05 93.12-26.76 126.55-26.98 31.3-68.47 47.77-110.99 44.05-61.86-5.41-127.27 34.36-127.92 34.76a23.966 23.966 0 0 1-12.63 3.59z"
        className="st1"
      />
      <path
        d="M712.08 662.45c-1.83 0-3.68-.21-5.53-.64-12.98-3.04-21.03-16.03-17.98-29.01.86-3.67 21.8-90.56 81.83-140.95 21.15-17.75 32.16-42.93 30.22-69.09-2.08-28.11-19.01-53.26-47.66-70.83-11.36-6.97-14.92-21.83-7.96-33.19 6.97-11.36 21.83-14.92 33.19-7.96 42.24 25.9 67.29 64.4 70.56 108.4 3.06 41.21-14.64 82.19-47.33 109.63-47.87 40.17-65.69 114.25-65.87 115-2.6 11.14-12.52 18.64-23.47 18.64z"
        className="st0"
      />
      <path
        d="M741.27 484.91c-8.07 0-15.95-4.05-20.51-11.41-1.99-3.21-48.63-79.45-41.8-157.52 2.41-27.51-7.61-53.1-27.48-70.23-21.35-18.4-51.1-24.22-83.78-16.38-12.95 3.1-25.99-4.88-29.1-17.84-3.11-12.96 4.88-25.99 17.84-29.1 48.18-11.55 93.12-2.05 126.55 26.76 31.3 26.98 47.77 68.47 44.05 110.99-5.41 61.86 34.36 127.27 34.76 127.92 6.98 11.34 3.47 26.2-7.86 33.2a24.06 24.06 0 0 1-12.67 3.61z"
        className="st1"
      />
      <path
        d="M636.41 338.86c-1.83 0-3.68-.21-5.53-.64-3.67-.86-90.56-21.8-140.94-81.83-17.75-21.15-42.94-32.16-69.09-30.22-28.11 2.08-53.26 19.01-70.83 47.66-6.97 11.36-21.83 14.92-33.19 7.96-11.36-6.97-14.92-21.83-7.96-33.19 25.9-42.24 64.4-67.29 108.41-70.56 41.19-3.05 82.19 14.64 109.63 47.33 40.17 47.87 114.25 65.69 115 65.87 12.98 3.04 21.03 16.03 17.98 29.01-2.61 11.11-12.53 18.61-23.48 18.61z"
        className="st0"
      />
      <path
        d="M204.04 488.96c-10.9 0-20.79-7.44-23.45-18.51-11.55-48.18-2.05-93.12 26.76-126.55 26.98-31.3 68.49-47.76 110.99-44.05 62.26 5.46 127.24-34.34 127.89-34.74 11.33-7.02 26.2-3.53 33.23 7.79 7.02 11.33 3.53 26.21-7.79 33.23-3.21 1.99-79.46 48.63-157.53 41.8-27.49-2.41-53.1 7.61-70.22 27.48-18.4 21.35-24.22 51.1-16.38 83.78 3.11 12.96-4.88 25.99-17.84 29.1-1.9.45-3.79.66-5.66.67z"
        className="st1"
      />
    </svg>
  </Box>
);
