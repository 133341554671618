/**
 * @generated SignedSource<<0c7ea7e77c084da418c711d3edb30fe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useRouteQuery$variables = {
  id: string;
};
export type useRouteQuery$data = {
  readonly node: {
    readonly __typename: string;
    readonly id: string;
    readonly ownershipEvent?: {
      readonly id: string;
    } | null;
    readonly ownershipFolders?: {
      readonly nodes: ReadonlyArray<{
        readonly id: string;
      }>;
    };
    readonly ownershipSpace?: {
      readonly id: string;
    };
  } | null;
};
export type useRouteQuery = {
  response: useRouteQuery$data;
  variables: useRouteQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Space",
            "kind": "LinkedField",
            "name": "ownershipSpace",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Event",
            "kind": "LinkedField",
            "name": "ownershipEvent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "last",
                "value": 1
              }
            ],
            "concreteType": "FolderConnection",
            "kind": "LinkedField",
            "name": "ownershipFolders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "ownershipFolders(last:1)"
          }
        ],
        "type": "HasOwnerPermissionsInterface",
        "abstractKey": "__isHasOwnerPermissionsInterface"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRouteQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRouteQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ad4942129aed3caed1af4e013cd11b57",
    "id": null,
    "metadata": {},
    "name": "useRouteQuery",
    "operationKind": "query",
    "text": "query useRouteQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    id\n    ... on HasOwnerPermissionsInterface {\n      __isHasOwnerPermissionsInterface: __typename\n      ownershipSpace {\n        id\n      }\n      ownershipEvent {\n        id\n      }\n      ownershipFolders(last: 1) {\n        nodes {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ddd9fa9853f3624469b22cb0e8d14f9";

export default node;
