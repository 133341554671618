import {
  Button,
  ButtonProps as chakraButtonProps,
  forwardRef,
} from '@cardboard-ui/react';
import React from 'react';

interface ButtonProps extends chakraButtonProps {
  analyticsEventDescription?: string;
}

export const PrimaryButton = forwardRef<ButtonProps, 'button'>(
  ({ children, onClick, isDisabled, isLoading, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        colorScheme={props.colorScheme || 'blue'}
        size={props.size || 'md'}
        onClick={(args) => {
          onClick && onClick(args);
        }}
        isDisabled={isDisabled}
        isLoading={isLoading}
        {...props}
      >
        {children}
      </Button>
    );
  },
);
