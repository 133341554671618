/* eslint-disable no-restricted-imports */

import React, { FC } from 'react';
import {
  BreadcrumbLink as ChakraBreadcrumbLink,
  BreadcrumbLinkProps as ChakraBreadcrumbLinkProps,
  forwardRef as chakraForwardRef,
} from '@chakra-ui/react';
import { Link, LinkProps } from '@cardboard-ui/react';

export type BreadcrumbLinkProps = Pick<
  ChakraBreadcrumbLinkProps,
  'isCurrentPage'
> &
  LinkProps;
export const BreadcrumbLink: FC<BreadcrumbLinkProps> = chakraForwardRef<
  BreadcrumbLinkProps,
  'a'
>((props, ref) => {
  return <ChakraBreadcrumbLink ref={ref} as={Link} {...props} />;
});
