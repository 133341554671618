import { ColorMode, ComponentStyleConfig } from '@cardboard-ui/react';
import { createContext } from 'react';
import {
  DefaultDesktopMenuStyle,
  DesktopMenuSetting,
} from '../components/DesktopMenu';
import { DesktopMenuHeaderSetting } from '../components/DesktopMenuHeader';
import {
  DefaultPublicScreensSetting,
  PublicScreensSetting,
} from '../components/PublicScreens';
import { DefaultTextLinkStyle, TextLinkSetting } from '../components/TextLink';

export type ComponentStyleDictionary = { [name: string]: ComponentStyleConfig };

export const ColorModes = ['light', 'dark', 'system'];
export function isColorModeWithSystem(
  mode: string,
): mode is ColorModeWithSystem {
  return ColorModes.includes(mode);
}
export type ColorModeWithSystem = ColorMode | 'system';
export interface CustomTheme {
  readonly desktopMenu: DesktopMenuSetting;
  readonly textLink: TextLinkSetting;
  readonly desktopMenuHeader?: DesktopMenuHeaderSetting;
  readonly publicScreens: PublicScreensSetting;
}

export const DefaultCustomTheme: CustomTheme = {
  desktopMenu: DefaultDesktopMenuStyle,
  textLink: DefaultTextLinkStyle,
  publicScreens: DefaultPublicScreensSetting,
};

interface CustomThemeContextInterface {
  readonly activeCustomTheme: CustomTheme;
  colorMode: ColorModeWithSystem;
  setColorMode: (newColorMode: ColorModeWithSystem) => void;
  hasLegacyTheme: boolean;
}

const stub = (): never => {
  throw new Error(
    'This should be replaced on DynamicCustomizableThemeProvider.',
  );
};

const initialCustomThemeContext: CustomThemeContextInterface = {
  activeCustomTheme: DefaultCustomTheme,
  colorMode: 'system',
  setColorMode: () => {},
  hasLegacyTheme: false,
};

export const CustomThemeContext = createContext<CustomThemeContextInterface>(
  initialCustomThemeContext,
);
