import { useExpiringLocalStorage } from 'hooks/useBrowserStorage';
import { useCallback } from 'react';
import { LoginScreenWithData } from './SignInLogic';

export const useSignInScreenState = () => {
  const [screen, setScreen] = useExpiringLocalStorage<LoginScreenWithData>(
    'login-screen',
    {
      screen: 'start',
    },
    'device',
    15 * 60 * 1000,
  );

  const resetScreen = useCallback(() => {
    setScreen({ screen: 'start' });
  }, [setScreen]);

  return [screen, setScreen, resetScreen] as const;
};
