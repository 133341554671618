import { createContext, Dispatch, SetStateAction } from 'react';

const stub = (): never => {
  throw new Error('This should be replaced on MyVaultsProvider.');
};

export enum Mode {
  INBOX = 'inbox',
  TABLE = 'table',
  HIERARCHICAL = 'hierarchical',
  SEARCH = 'search',
}

export enum FilterType {
  VAULT = 'vault',
  LABEL = 'label',
}

export type Filter = {
  id: string;
  type: FilterType;
  name: string;
  isSelected?: boolean;
};

export enum Sort {
  NEWEST_TO_OLDEST = 'newest_to_oldest',
  OLDEST_TO_NEWEST = 'oldest_to_newest',
}

export type OrderBy = { publishedAt: 'DESC' | 'ASC' };

type MyVaultsContextType = {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  isModeToggleDisabled: boolean;
  mode: Mode;
  setMode: (newMode: Mode) => void;

  isFiltersVisible: boolean;
  setIsFiltersVisible: Dispatch<SetStateAction<boolean>>;

  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  getOrderBy: () => OrderBy;

  vaultFilters: Filter[];
  labelFilters: Filter[];
  toggleFilter: (filter: Filter) => void;
  getIsEveryFilterUnselected: (filters: Filter[]) => boolean;
};

const initialContext: MyVaultsContextType = {
  searchQuery: '',
  setSearchQuery: stub,

  isModeToggleDisabled: false,
  mode: Mode.INBOX,
  setMode: stub,

  isFiltersVisible: true,
  setIsFiltersVisible: stub,

  sort: Sort.NEWEST_TO_OLDEST,
  setSort: stub,
  getOrderBy: stub,

  vaultFilters: [],
  labelFilters: [],
  toggleFilter: stub,
  getIsEveryFilterUnselected: stub,
};

export const MyVaultsContext =
  createContext<MyVaultsContextType>(initialContext);
