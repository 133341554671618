import { AccordionPanel, Box, BoxProps } from '@cardboard-ui/react';
import * as React from 'react';

const SectionPadding = { base: 4, md: 6 };

export const SectionContent = (props: BoxProps) => (
  <Box pt={SectionPadding} pb={SectionPadding} px={SectionPadding} {...props} />
);

export const CollapsableSectionContent = (props: BoxProps) => (
  <AccordionPanel
    pt={SectionPadding}
    pb={SectionPadding}
    px={SectionPadding}
    {...props}
  />
);
