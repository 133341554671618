import React from 'react';
import App from './App';
import * as Sentry from '@sentry/react';
import * as SentryCapacitor from '@sentry/capacitor';
import { createRoot } from 'react-dom/client';
import { Capacitor } from '@capacitor/core';
import { ResourceLoadingPlugin } from 'plugins/resourceLoading';
import { CapacitorUpdater } from '@capgo/capacitor-updater';

declare global {
  const REACT_APP_FORCE_REACT_18: 'react18' | string;
  const REACT_APP_IOS_CAPACITOR_SENTRY_ID: string | null;
  const REACT_APP_ANDROID_CAPACITOR_SENTRY_ID: string | null;
  const REACT_APP_CAPACITOR_BUILD_NUMBER: string | null;
  const REACT_APP_MOBILE_APP_VERSION: string | null;

  interface Window {
    REACT_APP_SENTRY_ID: string;
    REACT_APP_RELEASE_ID: string;
    REACT_APP_GOOGLE_CLIENT_ID?: string;
    REACT_APP_GOOGLE_API_KEY?: string;
  }
}

if (window.REACT_APP_SENTRY_ID && window.REACT_APP_RELEASE_ID) {
  Sentry.init({
    dsn: window.REACT_APP_SENTRY_ID,
    release: window.REACT_APP_RELEASE_ID,
  });
} else if (Capacitor.isNativePlatform() && REACT_APP_CAPACITOR_BUILD_NUMBER) {
  if (Capacitor.getPlatform() === 'ios' && REACT_APP_IOS_CAPACITOR_SENTRY_ID) {
    SentryCapacitor.init({
      dsn: REACT_APP_IOS_CAPACITOR_SENTRY_ID,
      // Set your release version, such as 'getsentry@1.0.0'
      release: `govity.${Capacitor.getPlatform()}@${REACT_APP_MOBILE_APP_VERSION}`,
      // Set your dist version, such as "1"
      dist: REACT_APP_CAPACITOR_BUILD_NUMBER,
    });
  } else if (
    Capacitor.getPlatform() === 'android' &&
    REACT_APP_ANDROID_CAPACITOR_SENTRY_ID
  ) {
    SentryCapacitor.init({
      dsn: REACT_APP_ANDROID_CAPACITOR_SENTRY_ID,
      // Set your release version, such as 'getsentry@1.0.0'
      release: `govity.${Capacitor.getPlatform()}@${REACT_APP_MOBILE_APP_VERSION}`,
      // Set your dist version, such as "1"
      dist: REACT_APP_CAPACITOR_BUILD_NUMBER,
    });
  }
}

if (Capacitor.getPlatform() === 'android') {
  ResourceLoadingPlugin.registerResourceLoader();
}

CapacitorUpdater.notifyAppReady().then((info) => {
  Sentry.setExtra('native_app_bundle', info.bundle.version);
});

CapacitorUpdater.addListener('appReady', (info) => {
  Sentry.setExtra('native_app_bundle', info.bundle.version);
});

function renderAppToRoot() {
  const root = createRoot(document.getElementById('root')!);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

renderAppToRoot();

if (module.hot) {
  module.hot.accept('./App', () => {
    renderAppToRoot();
  });
}
