import React from 'react';
import { ButtonProps, forwardRef, IconButton } from '@cardboard-ui/react';

export interface LayoutIconButtonProps extends ButtonProps {
  icon: React.ReactElement;
  'aria-label': string;
}

export const LayoutIconButton = forwardRef<LayoutIconButtonProps, 'button'>(
  (props, ref) => (
    <IconButton
      ref={ref}
      variant="ghost"
      border={0}
      borderRadius={0}
      {...props}
    />
  ),
);
