import React, { FC } from 'react';
import { Button, ButtonProps, forwardRef } from '@cardboard-ui/react';
import {} from '.';

export const SecondaryButton: FC<ButtonProps> = forwardRef<
  ButtonProps,
  'button'
>((props, ref) => (
  <Button
    ref={ref}
    colorScheme={props.colorScheme || 'gray'}
    size={props.size || 'md'}
    variant="outline"
    {...props}
  />
));
