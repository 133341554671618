import {
  ComponentStyleConfig,
  useColorMode,
  useStyleConfig,
} from '@cardboard-ui/react';
import { SettingWithColorModeVariants } from '../types';

export interface TextLinkVariant {
  readonly textColor: string;
  readonly highlightTextColor: string;
}
export type TextLinkSetting = SettingWithColorModeVariants<TextLinkVariant>;

export const DefaultTextLinkStyle: TextLinkSetting = {
  light: {
    textColor: '#2B6CB0',
    highlightTextColor: '#2A4365',
  },
  dark: {
    textColor: '#4299E1',
    highlightTextColor: '#90CDF4',
  },
};

export const TextLinkThemeKey = 'TextLink';

export function useTextLinkStyle() {
  return useStyleConfig(TextLinkThemeKey, {
    variant: useColorMode().colorMode,
  });
}

export function textLinkCustomSettingToStyleConfig(
  style: TextLinkSetting,
): ComponentStyleConfig {
  return {
    baseStyle: {
      color: style.light.textColor,
      _hover: {
        textDecor: 'underline',
        color: style.light.highlightTextColor,
      },
      _activeLink: {
        color: style.light.highlightTextColor,
      },
    },
    variants: {
      light: {
        color: style.light.textColor,
        _hover: {
          color: style.light.highlightTextColor,
        },
        _activeLink: {
          color: style.light.highlightTextColor,
        },
      },
      dark: {
        color: style.dark.textColor,
        _hover: {
          color: style.dark.highlightTextColor,
        },
        _activeLink: {
          color: style.dark.highlightTextColor,
        },
      },
    },
  };
}

export const TextLink: ComponentStyleConfig =
  textLinkCustomSettingToStyleConfig(DefaultTextLinkStyle);
