import React, { FC, MouseEventHandler, PropsWithChildren } from 'react';
import {
  Box,
  BoxProps,
  forwardRef,
  useColorModeValue,
} from '@cardboard-ui/react';

export interface CardProps extends BoxProps {
  highLightColor?: string;
  onClick?: MouseEventHandler;
  isNotClickable?: boolean;
}

export const Card: FC<CardProps> = forwardRef<CardProps, 'div'>(
  (
    {
      highLightColor,
      onClick,
      children,
      width,
      height,
      isNotClickable,
      ...boxProps
    },
    ref,
  ) => {
    const borderStartWidth = highLightColor ? 10 : 1;
    const bgColor = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.100', 'gray.900');
    const shadowColor = useColorModeValue(
      'rgba(0, 0, 0, 0.1)',
      'rgba(255, 255, 255, 0.1)',
    );

    return (
      <Box
        ref={ref}
        shadow="sm"
        borderWidth="1px"
        borderColor={borderColor}
        borderRadius="md"
        borderStartWidth={borderStartWidth}
        borderStartColor={highLightColor}
        bgColor={bgColor}
        overflow="hidden"
        onClick={onClick}
        cursor={onClick ? 'pointer' : 'initial'}
        width={width}
        height={height}
        _hover={
          !isNotClickable
            ? {
                boxShadow: `0px 0px 50px 1px ${shadowColor}`,
              }
            : undefined
        }
        transition=".3s"
        {...boxProps}
      >
        {children}
      </Box>
    );
  },
);

export const CardContent: FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box p={3} flexGrow={1} {...props}>
      {children}
    </Box>
  );
};
