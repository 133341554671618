import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Collapse,
  Divider,
  forwardRef,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
  TokenInput,
  useInterval,
  useToast,
  VStack,
} from '@cardboard-ui/react';
import { useTenantSession } from 'utils/sessionProvider';
import { t, Trans } from '@lingui/macro';
import { AuthenticationScreen } from '../../../AuthenticationScreen';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';
import { Form } from 'components/Form';
import { Input, PasswordInputFormControl } from 'components/Form/Input';
import { FORGOT_PASSWORD_PATH } from 'utils/routes';
import { authenticatedHttpRequest } from 'utils/http';
import {
  KeyIcon,
  NavigateBackIcon,
  PaperPlaneIcon,
  SuccessIcon,
} from 'components/icons';
import { useSearchParams } from 'react-router-dom';
import { TextLink } from 'apps/TenantApp/components/TextLink';
import { AuthenticationScreenHeading } from '../../../AuthenticationScreenHeading';
import AuthButton from '../../../AuthButton';
import { AlreadySignedIn } from '../../../AlreadySignedIn';
import useFetchKey from 'hooks/useFetchKey';
import * as Sentry from '@sentry/react';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';

type SignInMode = 'password' | 'email';

interface FormFields {
  login: string;
  password: string;
}

export const SignInModal = forwardRef<{}, 'div'>(({}, ref) => {
  const [searchParams] = useSearchParams();
  const { isAuthenticated, authenticate } = useTenantSession();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [signInMode, setSignInMode] = useState<SignInMode>(
    searchParams.get('email') ? 'email' : 'password',
  );
  const [_emailRequesting, setEmailRequesting] = useState(false);
  const [emailRequesting, _setEmailRequesting] = useState(false);
  const [emailRequested, setEmailRequested] = useState(false);
  const [emailAddressRequested, setEmailAddressRequested] = useState<string>();
  const [authToken, setAuthToken] = useState<string>();
  const [authTokenFetchKey, resetAuthTokenFetchKey] = useFetchKey();
  const [, persistReturnToPath, clearPersistedReturnToPath] =
    usePersistedReturnToPath();
  const [signinError, setSigninError] = useState('');
  const { register, handleSubmit, formState } = useForm<FormFields>();
  const [emailSignInToken, setEmailSignInToken] = useState<'string'>();
  const emailSignInMode = emailSignInToken === undefined ? 'link' : 'token';
  const toast = useToast();
  const returnToPath = window.location.href.replace(window.location.origin, '');

  const resetScreen = useCallback(() => {
    startTransition(() => {
      setEmailRequested(false);
      _setEmailRequesting(false);
      setSigninError('');
      setIsAuthenticating(false);
      setEmailAddressRequested(undefined);
      setSignInMode(searchParams.get('email') ? 'email' : 'password');
      setAuthToken(undefined);
      setEmailSignInToken(undefined);
    });
  }, []);

  const fetchExternalAuthToken = useCallback(() => {
    authenticatedHttpRequest('/auth/external-auth-token', {
      method: 'POST',
    }).then(async (r) => {
      try {
        const data = await r.json();
        setAuthToken(data.token);
      } catch (e) {
        Sentry.withScope((s) => {
          s.setLevel('warning');
          s.setTag('http_error', 'fetchExternalAuthToken');
          Sentry.captureException(e);
        });
        setTimeout(resetAuthTokenFetchKey, 5000);
      }
    });
  }, [authTokenFetchKey, setAuthToken]);

  useEffectOnceWhen(true, fetchExternalAuthToken);
  useInterval(resetAuthTokenFetchKey, 45 * 60 * 1000);

  const onSubmit = useCallback(
    (args: FormFields) => {
      if (signInMode === 'password') {
        onPasswordSignIn(args);
      } else {
        onEmailSignIn(args);
      }
    },
    [signInMode],
  );

  useEffect(() => {
    if (_emailRequesting) {
      _setEmailRequesting(true);
    } else {
      setTimeout(() => _setEmailRequesting(false), 500);
    }
  }, [_emailRequesting]);

  const onPasswordSignIn = useCallback(
    ({ login, password }: { login: string; password: string }) => {
      setIsAuthenticating(true);
      setSigninError('');
      authenticatedHttpRequest('/auth/sign-in', {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          email: login,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(async (res) => {
          if (res.status == 200) {
            const data = await res.json();
            authenticate();
          } else {
            setSigninError('Login failed');
          }
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    },
    [],
  );

  const onEmailSignIn = useCallback(({ login }: { login: string }) => {
    setEmailRequesting(true);
    setSigninError('');
    persistReturnToPath(returnToPath);
    authenticatedHttpRequest('/auth/request-email-auth', {
      method: 'POST',
      body: JSON.stringify({
        email: login,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();

          if (!data.error) {
            setEmailRequested(true);
            setEmailAddressRequested(login);
            setEmailSignInToken(data.token);
          } else {
            switch (data.status_code) {
              case 'INVALID_EMAIL':
                setSigninError(t`Invalid email address`);
                break;
              case 'RATE_LIMIT_EXCEEDED':
                setSigninError(
                  t`We already handled a sign-in request for this email. Please try again in a few minutes`,
                );
                break;
            }
          }
        } else {
          clearPersistedReturnToPath();
          setSigninError(t`Email link request failed`);
        }
      })
      .finally(() => {
        setEmailRequesting(false);
      });
  }, []);

  const onEmailSignInWithPin = useCallback(
    ({ pin }: { pin: string }) => {
      if (!isAuthenticated) {
        setIsAuthenticating(true);
        setSigninError('');
        authenticatedHttpRequest('/auth/email-auth', {
          method: 'POST',
          body: JSON.stringify({
            key: emailSignInToken,
            pin,
          }),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        })
          .then(async (res) => {
            if (res.status == 200) {
              const data = await res.json();
              authenticate();
            } else {
              setSigninError('Login failed');
              toast({
                title: t`The pin provided is not correct`,
                status: 'error',
                description: t`If this error persists, try requesting the sign-in email again.`,
              });
            }
          })
          .finally(() => {
            setIsAuthenticating(false);
          });
      }
    },
    [emailSignInToken, isAuthenticated, authenticate],
  );

  if (isAuthenticated) {
    return <AlreadySignedIn ref={ref} returnToPath={returnToPath} />;
  }

  return (
    <SignInScreenSkeleton
      signInMode={signInMode}
      setSignInMode={setSignInMode}
      isAuthenticating={isAuthenticating}
      signinError={signinError}
      emailRequested={emailRequested}
      emailRequesting={emailRequesting}
      emailAddressRequested={emailAddressRequested}
      emailSignInMode={emailSignInMode}
      onEmailSignInWithPin={onEmailSignInWithPin}
      onSubmit={handleSubmit(onSubmit)}
      loginInputProps={{
        ...register('login', {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: t`Invalid email address`,
          },
        }),
        errorMessage: signinError || formState.errors.login?.message,
      }}
      passwordInputProps={{ ...register('password') }}
      authOptions={window.preload_AuthOptions || []}
      authToken={authToken}
      resetScreen={resetScreen}
    />
  );
});

interface SignInScreenSkeletonProps {
  signInMode: SignInMode;
  setSignInMode(mode: SignInMode): void;
  isAuthenticating?: boolean;
  signinError?: string;
  emailRequested?: boolean;
  emailRequesting?: boolean;
  emailAddressRequested?: string;
  emailSignInMode: 'link' | 'token';
  onEmailSignInWithPin({ pin }: { pin: string }): void;
  onSubmit(values: any): void;
  loginInputProps?: any;
  passwordInputProps?: any;
  authOptions: { id: string; url?: string }[];
  authToken: string | undefined;
  resetScreen: () => void;
}

declare global {
  interface Window {
    reloadAfterAuthClosed: (targetUrl: string) => void;
    preload_AuthOptions?: { id: string; url?: string }[];
  }
}

export const SignInScreenSkeleton = forwardRef<
  SignInScreenSkeletonProps,
  'div'
>(
  (
    {
      setSignInMode,
      signInMode,
      isAuthenticating,
      signinError,
      emailRequested,
      emailRequesting,
      emailAddressRequested: email,
      emailSignInMode,
      onEmailSignInWithPin,
      onSubmit,
      loginInputProps,
      passwordInputProps,
      authOptions,
      authToken,
      resetScreen,
    },
    ref,
  ) => {
    const { tenant, authenticate } = useTenantSession();
    const externalAuthOptions = authOptions.filter(({ url }) => !!url) as {
      id: string;
      url: string;
    }[];

    useEffect(() => {
      window.reloadAfterAuthClosed = (targetUrl: string) => {
        if (targetUrl !== window.location.href) {
          // eslint-disable-next-line no-restricted-properties
          window.location.assign(targetUrl);
        } else {
          authenticate();
        }
      };

      return () => {
        window.reloadAfterAuthClosed = () => {};
      };
    }, []);

    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>
            <HStack justifyContent="space-between">
              <span>
                {t`Sign in to`} {tenant.name}
              </span>
              {tenant.icon?.url && (
                <Image
                  src={tenant.icon?.url}
                  borderWidth="2px"
                  borderRadius="md"
                  borderColor="white"
                  borderStyle="solid"
                  alt=""
                  boxSize="50px"
                  objectFit="cover"
                />
              )}
            </HStack>
          </AuthenticationScreenHeading>
          <Box>
            <Collapse in={emailRequested || emailRequesting} animateOpacity>
              <VStack pt={1}>
                <PaperPlaneIcon size="4x" />
                {emailRequesting && <Spinner size="xs" />}

                {!emailRequesting &&
                  (emailSignInMode === 'link' ? (
                    <Box data-qa="email-sent-confirmation" color="green.500">
                      <SuccessIcon />
                    </Box>
                  ) : isAuthenticating ? (
                    <Spinner />
                  ) : (
                    <VStack data-qa="email-sent-confirmation" py={4}>
                      <Box>{t`Enter the code from the email below:`}</Box>
                      <HStack>
                        <TokenInput
                          otp
                          autoFocus
                          expectedLength={6}
                          onComplete={(pin) => onEmailSignInWithPin({ pin })}
                        />
                      </HStack>
                    </VStack>
                  ))}
                <small>
                  <Trans>
                    Email sent to <strong>{email}</strong>. It can take a minute
                    before it arrives. If you didn't receive an email, please
                    make sure you've entered the correct address.
                  </Trans>
                </small>
                <Button variant="link" onClick={resetScreen}>
                  <NavigateBackIcon /> {t`Back`}
                </Button>
              </VStack>
            </Collapse>
            <Collapse in={!(emailRequested || emailRequesting)} animateOpacity>
              <Form
                CTASubmitName={
                  signInMode === 'password' ? t`Sign in` : t`Send Sign-in link`
                }
                onSubmit={onSubmit}
                isSubmitting={isAuthenticating}
              >
                <Input
                  formid="email"
                  label="Your email"
                  autoComplete="username"
                  errorMessage={signinError}
                  type="email"
                  {...loginInputProps}
                />
                <Collapse in={signInMode === 'password'} animateOpacity>
                  <PasswordInputFormControl
                    label={t`Password`}
                    placeholder=""
                    autoComplete="current-password"
                    {...passwordInputProps}
                  />
                  <TextLink
                    to={FORGOT_PASSWORD_PATH}
                  >{t`Forgot your password?`}</TextLink>
                </Collapse>
              </Form>
            </Collapse>

            <Collapse
              in={!(isAuthenticating || emailRequested || emailRequesting)}
              animateOpacity
            >
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  paddingY={4}
                  gridGap={4}
                >
                  <Divider borderColor="black" />
                  <Text flexGrow={1} fontSize="sm" whiteSpace="nowrap">
                    <KeyIcon /> {t`Other Sign-in Options`}
                  </Text>
                  <Divider borderColor="black" />
                </Box>
                <VStack alignItems="stretch" w="100%">
                  {signInMode === 'password' ? (
                    <Button
                      onClick={() => setSignInMode('email')}
                      colorScheme="green"
                      leftIcon={<PaperPlaneIcon />}
                    >{t`Sign-in with E-mail`}</Button>
                  ) : (
                    <Button
                      onClick={() => setSignInMode('password')}
                      colorScheme="green"
                      leftIcon={<KeyIcon />}
                    >{t`Sign-in with Password`}</Button>
                  )}
                  {externalAuthOptions.map(({ url, id }) => (
                    <AuthButton
                      key={id}
                      id={id}
                      token={authToken}
                      href={url as string}
                    />
                  ))}
                </VStack>
              </Box>
            </Collapse>
          </Box>
          <Box />
        </Stack>
      </AuthenticationScreen>
    );
  },
);

export default SignInModal;
