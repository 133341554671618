/* eslint-disable no-restricted-imports */

import React, { useCallback } from 'react';
import { InputProps as ChakraInputProps, forwardRef } from '@chakra-ui/react';
import { Input as BaseInput } from '../Input';

type onCompleteProps =
  | {
      onComplete: (value: string) => void;
      expectedLength: number;
    }
  | {
      onComplete?: never;
      expectedLength?: never;
    };

export const TokenInput = forwardRef<
  ChakraInputProps & { otp?: boolean } & onCompleteProps,
  'input'
>(({ onComplete, expectedLength, onChange, ...props }, ref) => {
  const otpProps = props.otp
    ? {
        placeholder: Array((expectedLength || 6) + 1).join('○'),
        autoComplete: 'one-time-code',
        inputmode: 'numeric',
        type: 'tel',
      }
    : {};

  const handleOnComplete = useCallback(
    (value: string) => {
      if (onComplete && value.length >= expectedLength) {
        // Slow down the onComplete ever so slightly
        setTimeout(() => {
          onComplete(value);
        }, 25);
      }
    },
    [onComplete, expectedLength],
  );

  return (
    <BaseInput
      ref={ref}
      fontFamily="monospace"
      letterSpacing={4}
      textAlign="center"
      onChange={(e) => {
        onChange && onChange(e);
        handleOnComplete(e.target.value);
      }}
      {...otpProps}
      {...props}
    />
  );
});
