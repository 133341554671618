import { createContext } from 'react';

export enum Layout {
  MOBILE,
  DESKTOP,
  IONIC_MOBILE,
}
interface LayoutContextInterface {
  activeLayout: Layout;
}

const initialLayoutContext: LayoutContextInterface = {
  activeLayout: Layout.DESKTOP,
};

export const LayoutContext =
  createContext<LayoutContextInterface>(initialLayoutContext);
