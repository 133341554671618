import { createContext } from 'react';

export interface ThumbnailRefreshInterface {
  addNewThumbnailNode: (folderNodeId: string) => void;
}

const initialThumbnailRefreshContext: ThumbnailRefreshInterface = {
  addNewThumbnailNode: () => {},
};

export const ThumbnailRefreshContext = createContext<ThumbnailRefreshInterface>(
  initialThumbnailRefreshContext,
);
