import { graphql, useMutation } from 'utils/graphClient';
import { AvailableLocale } from '.';
import { useSession } from '../sessionProvider';
import { usePersistMemberLanguageCodeMutation } from './__generated__/usePersistMemberLanguageCodeMutation.graphql';

export const usePersistMemberLanguageCode = () => {
  const { member } = useSession();
  const [updateLanguage] =
    useMutation<usePersistMemberLanguageCodeMutation>(updateMemberMutation);

  return (languageCode: AvailableLocale) =>
    new Promise((resolve) => {
      if (!member) {
        resolve(null);
      } else {
        updateLanguage({
          variables: { id: member.id, languageCode },
          onCompleted: () => resolve(null),
        });
      }
    });
};
const updateMemberMutation = graphql`
  mutation usePersistMemberLanguageCodeMutation(
    $id: ID!
    $languageCode: LanguageCode
  ) {
    updateMember(input: { id: $id, languageCode: $languageCode }) {
      memberUpdated {
        id
        languageCode
      }
    }
  }
`;
