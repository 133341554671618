import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import { authenticatedHttpRequest } from 'utils/http';
import { DEFAULT_LOCALE } from 'utils/i18n';

const NOTIFICATIONS_DEVICE_TOKEN_KEY = 'NOTIFICATIONS_DEVICE_TOKEN';
const NOTIFICATIONS_REGISTER_DATE_JSON_KEY = 'NOTIFICATIONS_REGISTER_DATE_JSON';

export const registerNotifications = async () => {
  if (!Capacitor.isNativePlatform()) return;

  await PushNotifications.addListener('registration', async (token) => {
    console.info('Registration token: ', token.value);
    await Preferences.set({
      key: NOTIFICATIONS_DEVICE_TOKEN_KEY,
      value: token.value,
    });
    await subscribeDevice();
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.register();
};

export const requestPermission = async () => {
  if (!Capacitor.isNativePlatform()) return;

  let permStatus = await PushNotifications.checkPermissions();
  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }
  if (permStatus.receive !== 'granted') {
    console.warn('User denied permissions!');
    return false;
  }
  return true;
};

export const subscribeDevice = async () => {
  if (!Capacitor.isNativePlatform()) return;

  const { value: PushNotificationsIdentifier } = await Preferences.get({
    key: NOTIFICATIONS_DEVICE_TOKEN_KEY,
  });
  if (!PushNotificationsIdentifier) {
    return;
  }

  authenticatedHttpRequest(`/device/subscription`, {
    method: 'POST',
    body: JSON.stringify({
      identifier: PushNotificationsIdentifier,
      ...(await getSubscriptionInfo()),
    }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
};

export const unsubscribeDevice = async () => {
  if (!Capacitor.isNativePlatform()) return;

  const { value: PushNotificationsIdentifier } = await Preferences.get({
    key: NOTIFICATIONS_DEVICE_TOKEN_KEY,
  });
  if (!PushNotificationsIdentifier) {
    return;
  }

  authenticatedHttpRequest(`/device/subscription`, {
    method: 'DELETE',
    body: JSON.stringify({
      identifier: PushNotificationsIdentifier,
      ...(await getSubscriptionInfo()),
    }),
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });
};

const getSubscriptionInfo = async () => {
  const appInfo = await App.getInfo();
  // const lang = await Device.getLanguageCode();
  const lang = window.document.body.lang || DEFAULT_LOCALE;
  const device = await Device.getInfo();
  const platform = Capacitor.getPlatform();
  return {
    app_version: appInfo.version,
    language: lang || 'en',
    device_model: device.model,
    device_os: device.osVersion,
    device_platform: platform,
  };
};

export const shouldUpdateNotificationRegistration = async (
  notificationId: string,
) => {
  const { value: registrationDateJson } = await Preferences.get({
    key: NOTIFICATIONS_REGISTER_DATE_JSON_KEY,
  });

  const data = (
    registrationDateJson ? JSON.parse(registrationDateJson) : {}
  ) as Record<string, string | undefined>;
  const registrationDate = data[notificationId];

  if (registrationDate === new Date().toLocaleDateString()) {
    return false;
  }

  const newData = {
    ...data,
    [notificationId]: new Date().toLocaleDateString(),
  };
  // No need to await, as we don't really care if it succeeds or not
  Preferences.set({
    key: NOTIFICATIONS_REGISTER_DATE_JSON_KEY,
    value: JSON.stringify(newData),
  });
  return true;
};
