import basex from 'base-x';
import { Buffer } from 'buffer';

const BASE58 = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const { decode } = basex(BASE58);

const GraphqlIdSplitter = 36; // ascii code for $
const IdJoiner = '+';

export const decodeGraphqlId = (graphqlId: string): DecodedGraphQLId => {
  try {
    const decodedId = roughDecodeGraphqlId(graphqlId);

    if (!isWellFormed(decodedId)) {
      throw new Error('Malformed graphqlId');
    }
    return decodedId;
  } catch (e) {
    throw new Error('Malformed graphqlId');
  }
};

export const tryDecodeGraphqlId = (maybeGraphqlId: any) => {
  try {
    return decodeGraphqlId(String(maybeGraphqlId));
  } catch {
    return null;
  }
};

const PrefixRegex = /^[A-Z]{1,11}$/;
const isWellFormed = (decodedId: DecodedGraphQLId): boolean => {
  return !!decodedId.id && PrefixRegex.test(decodedId.prefix);
};

type DecodedGraphQLId = {
  id: string;
  shortcode: string | null;
  prefix: string;
  suffix: string | null;
};

const roughDecodeGraphqlId = (graphqlId: string): DecodedGraphQLId => {
  const decodedId = decode(graphqlId);

  if (bytesToString(decodedId)[0] === '1') {
    // Format: 1[prefix]$[suffix]$[id]
    const prefixEnd = decodedId.indexOf(GraphqlIdSplitter);
    const suffixEnd =
      decodedId.indexOf(GraphqlIdSplitter, prefixEnd) + prefixEnd;
    const rawId = decodedId.slice(prefixEnd + 1);

    return {
      prefix: bytesToString(decodedId.slice(1, prefixEnd)), // skip the first character which is "1"
      id: extractId(rawId),
      shortcode: extractShortcode(rawId),
      suffix: bytesToString(decodedId.slice(prefixEnd + 1, suffixEnd)),
    };
  } else {
    // Format: [prefix]$[id]
    const prefixEnd = decodedId.indexOf(GraphqlIdSplitter);
    const rawId = decodedId.slice(prefixEnd + 1);

    return {
      prefix: bytesToString(decodedId.slice(0, prefixEnd)),
      id: extractId(rawId),
      shortcode: extractShortcode(rawId),
      suffix: null,
    };
  }
};

const extractShortcode = (arr: Uint8Array) => {
  const shortcodeLength = arr.length % 16; // how many bytes are left after extracting the uuids
  if (!shortcodeLength) {
    return null;
  }
  const shortcodeIndex = arr.length - shortcodeLength;
  return bytesToHex(arr.slice(shortcodeIndex));
};

// Basically we extract all uuids, format them and join them with a "+"
const extractId = (arr: Uint8Array) => {
  const matchIterator = bytesToHex(arr).matchAll(
    /(.{8})(.{4})(.{4})(.{4})(.{12})/g,
  );

  const uuids = [] as string[];
  let step = matchIterator.next();
  while (!step.done) {
    // We ignore the first value, as that is the entire match.
    // The rest of the array are the matched groups
    uuids.push(step.value.slice(1).join('-'));
    step = matchIterator.next();
  }
  return uuids.join(IdJoiner);
};

const bytesToHex = (arr: Uint8Array) => Buffer.from(arr).toString('hex');
const bytesToString = (arr: Uint8Array) =>
  // We filter the 0 values (0 is falsy, and NULL character) as those are used to pad the graphqlId
  // We don't want them in our end result
  String.fromCharCode(...Array.from(arr).filter((c) => c));
