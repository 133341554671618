import { useBrowserStorage } from './useBrowserStorage';

const SessionStorageIdentifier = 'SignInReturnTo';
const DefaultPath = '/';

export const useReturnToPath = () => useGenericReturnToPath(false);
export const usePersistedReturnToPath = () => useGenericReturnToPath(true);

const useGenericReturnToPath = (
  isPersistent: boolean,
): [string, (path: string) => void, () => void] => {
  const [returnToPath, setReturnToPathInStorage, clearReturnToPath] =
    useBrowserStorage<string | undefined>(
      SessionStorageIdentifier,
      DefaultPath,
      isPersistent ? 'local' : 'session',
      'device',
    );

  const setReturnToPath = (value: string) => {
    if (invalidReturnPath(value)) {
      throw new Error('invalidReturnPath');
    }
    setReturnToPathInStorage(value);
  };

  return [returnToPath || DefaultPath, setReturnToPath, clearReturnToPath];
};

const invalidReturnPath = (path: string) => {
  return path[0] !== '/';
};
