import { AvailableLocale } from '.';
import { dateFnsLocale } from './dateFnsLocale';
import { formatDistance } from 'date-fns';
import { parseApplicationDate } from './parseApplicationDate';

export type FormatDistanceFn = (
  date: Parameters<typeof parseApplicationDate>[0],
  baseDate: Parameters<typeof parseApplicationDate>[0],
  options?: {
    addSuffix?: boolean;
    includeSeconds?: boolean;
    ensurePast?: boolean;
  },
) => string;

export const buildFormatDistance =
  (locale: AvailableLocale): FormatDistanceFn =>
  (date, baseDate, options) => {
    date = parseApplicationDate(date);
    baseDate = parseApplicationDate(baseDate);
    if (options?.ensurePast) {
      baseDate = typeof baseDate === 'number' ? baseDate : baseDate.getTime();
      baseDate = Math.max(baseDate, Date.now());
    }
    options = options || {};
    return formatDistance(date, baseDate, {
      locale: dateFnsLocale(locale || 'other'),
      ...options,
    });
  };
