import { createContext, Dispatch, SetStateAction } from 'react';

const stub = (): never => {
  throw new Error('This should be replaced on PrivateVaultProvider.');
};

export enum Mode {
  TABLE = 'table',
  HIERARCHICAL = 'hierarchical',
  SEARCH = 'search',
}

export type LabelFilter = {
  id: string;
  name: string;
  isSelected?: boolean;
};

export enum Sort {
  NEWEST_TO_OLDEST = 'newest_to_oldest',
  OLDEST_TO_NEWEST = 'oldest_to_newest',
}

export type OrderBy = { publishedAt: 'DESC' | 'ASC' };

type PrivateVaultContextType = {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;

  isModeToggleDisabled: boolean;
  mode: Mode;
  setMode: (newMode: Mode) => void;

  isFiltersVisible: boolean;
  setIsFiltersVisible: Dispatch<SetStateAction<boolean>>;

  sort: Sort;
  setSort: Dispatch<SetStateAction<Sort>>;
  getOrderBy: () => OrderBy;

  labelFilters: LabelFilter[];
  toggleLabelFilter: (filter: LabelFilter) => void;
  getIsEveryLabelFilterUnselected: () => boolean;
};

const initialContext: PrivateVaultContextType = {
  searchQuery: '',
  setSearchQuery: stub,

  isModeToggleDisabled: false,
  mode: Mode.TABLE,
  setMode: stub,

  isFiltersVisible: true,
  setIsFiltersVisible: stub,

  sort: Sort.NEWEST_TO_OLDEST,
  setSort: stub,
  getOrderBy: stub,

  labelFilters: [],
  toggleLabelFilter: stub,
  getIsEveryLabelFilterUnselected: stub,
};

export const PrivateVaultContext =
  createContext<PrivateVaultContextType>(initialContext);
