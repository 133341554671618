import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  useSafeRelay,
} from 'utils/graphClient';
import {
  useRouteQuery,
  useRouteQuery$variables,
} from './__generated__/useRouteQuery.graphql';

export type RouteReference = PreloadedQuery<useRouteQuery>;

const RouteQuery = graphql`
  query useRouteQuery($id: ID!) {
    node(id: $id) {
      __typename
      id
      ... on HasOwnerPermissionsInterface {
        ownershipSpace {
          id
        }
        ownershipEvent {
          id
        }
        ownershipFolders(last: 1) {
          nodes {
            id
          }
        }
      }
    }
  }
`;

export const useRouteQueryLoader = () => {
  return useQueryLoader<useRouteQuery>(RouteQuery);
};

export const usePreloadedRouteQuery = (routeRef: RouteReference) => {
  return usePreloadedQuery<useRouteQuery>(RouteQuery, routeRef);
};

export const useLazyLoadRouteInfoQuery = (props: useRouteQuery$variables) => {
  const { useLazyLoadQuery } = useSafeRelay();

  return useLazyLoadQuery<useRouteQuery>(RouteQuery, props);
};
