import React, { FC, PropsWithChildren } from 'react';
import { Box, Center, Portal, Spinner, VStack } from '@cardboard-ui/react';
import useKeyPress from 'hooks/useKeyPress';

export const CenteredSpinner = ({
  debugName,
  debug = false,
}: {
  debugName?: String;
  debug?: boolean;
}) => {
  return (
    <TrueCenter debug={debug}>
      <VStack spacing={0}>
        <Center>
          <Spinner width="24px" height="24px" />
        </Center>
        <CenterSpinnerDebug debug={debug}>{debugName}</CenterSpinnerDebug>
      </VStack>
    </TrueCenter>
  );
};

interface WithDebug {
  debug: boolean;
}

export const CenterSpinnerDebug: FC<PropsWithChildren<WithDebug>> = ({
  children,
  debug,
}) => {
  const isAltPressed = useKeyPress({ key: 'Alt' }),
    showDebugMessage = isAltPressed || debug;

  if (!showDebugMessage) return null;

  // The below box allocates no vertical space in the DOM tree
  // This way, when it shows, the spinner does not move.
  // But the inner padding does create some space with the Spinner
  return (
    <Box h={0}>
      <Box pt={2}>{children}</Box>
    </Box>
  );
};

const TrueCenter: FC<PropsWithChildren<WithDebug>> = ({ children, debug }) => (
  <Portal>
    <Box
      position="absolute"
      backgroundColor={debug ? '#FF000050' : 'transparent'}
      top={0}
      left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
      pointerEvents="none"
      w="100vw"
      h="100vh"
    >
      {children}
    </Box>
  </Portal>
);
