import React, { FC, FormEvent, useCallback } from 'react';

const AuthPopupName = 'AuthWindow';

interface OAuthInitFormProps {
  id: string;
  href: string;
  token: string | undefined;
}

const browserSupportsPopupAuthFlow = false;

export const OAuthInitForm: FC<OAuthInitFormProps> = ({ id, href, token }) => {
  const useAuthPopup = !window.Cypress && browserSupportsPopupAuthFlow;

  const action = useAuthPopup ? linkWithPopupParam(href) : href;
  const onSubmit = useCallback(() => {
    if (!token) {
      return preventFormSubmit;
    }
    if (useAuthPopup) {
      return popupCenter('about:blank', AuthPopupName, 600, 570);
    }
    return () => {};
  }, [token, useAuthPopup]);

  // The form must always render a value for token, as React doesn't like `undefined` as an input.
  return (
    <form
      id={id}
      action={action}
      target={useAuthPopup ? AuthPopupName : '_self'}
      method="post"
      onSubmit={onSubmit}
    >
      <input
        type="hidden"
        name="crdbrd-csrf-token"
        value={token || 'missing'}
      />
      <input type="hidden" name="origin" value={window.location.href} />
    </form>
  );
};

export default OAuthInitForm;

const linkWithPopupParam = (link: string) => {
  const separator = link.indexOf('?') !== -1 ? '&' : '?';
  return link + separator + 'popup=true';
};

const preventFormSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();

// Create a named popup in the middle of the screen
const popupCenter = (
  linkUrl: string,
  name: string,
  width: number,
  height: number,
) => {
  const left = (screen.width - width) / 2;
  const top = (screen.height - height) / 2;
  return (
    window.open(
      linkUrl,
      name,
      `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`,
    ) || undefined
  );
};
