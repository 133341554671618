import React, { FC, PropsWithChildren } from 'react';
import {
  Button,
  Circle,
  Heading,
  Link,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { ScreenWithSingleSection } from 'layouts/ScreenWithSingleSection';
import { SearchIcon } from 'components/icons';

const NotFound = () => (
  <ScreenWithSingleSection>
    <NotFoundContent>
      <Button as={Link} to="/">{t`Go home`}</Button>
    </NotFoundContent>
  </ScreenWithSingleSection>
);

export const NotFoundContent: FC<PropsWithChildren> = ({ children }) => (
  <VStack spacing={5}>
    <Circle bg="blue.300" size="90px">
      <SearchIcon size="3x" color="white" />
    </Circle>
    <VStack>
      <Heading fontSize="xl">{t`Page not found`}</Heading>
      <Text maxWidth="sm" textAlign="center">
        {t`We could not find the content you were looking for.`}
      </Text>
      {children}
    </VStack>
  </VStack>
);

export default NotFound;
