// eslint-disable-next-line no-restricted-imports
import { format as dateFormat, OptionsWithTZ } from 'date-fns-tz';
import { AvailableLocale } from '.';
import { dateFnsLocale } from './dateFnsLocale';
import { parseApplicationDate } from './parseApplicationDate';

export type FormatTimezoneFn = (
  date: Parameters<typeof parseApplicationDate>[0],
  format: string,
  options: OptionsWithTZ,
) => string;

export const buildFormatTimezone =
  (locale: AvailableLocale): FormatTimezoneFn =>
  (date, format, options) => {
    return dateFormat(parseApplicationDate(date), format, {
      ...options,
      locale: dateFnsLocale(locale || 'other'),
    });
  };
