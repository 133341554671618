/**
 * @generated SignedSource<<2272d069eef50e4303c11462da95b1c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePersistMemberLanguageCodeMutation$variables = {
  id: string;
  languageCode?: LanguageCodeString | null;
};
export type usePersistMemberLanguageCodeMutation$data = {
  readonly updateMember: {
    readonly memberUpdated: {
      readonly id: string;
      readonly languageCode: LanguageCodeString | null;
    };
  } | null;
};
export type usePersistMemberLanguageCodeMutation = {
  response: usePersistMemberLanguageCodeMutation$data;
  variables: usePersistMemberLanguageCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languageCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Variable",
            "name": "languageCode",
            "variableName": "languageCode"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "MembersUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "updateMember",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Member",
        "kind": "LinkedField",
        "name": "memberUpdated",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "languageCode",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePersistMemberLanguageCodeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePersistMemberLanguageCodeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "611cded9c6b8dcfbf580dab3af0b69d2",
    "id": null,
    "metadata": {},
    "name": "usePersistMemberLanguageCodeMutation",
    "operationKind": "mutation",
    "text": "mutation usePersistMemberLanguageCodeMutation(\n  $id: ID!\n  $languageCode: LanguageCode\n) {\n  updateMember(input: {id: $id, languageCode: $languageCode}) {\n    memberUpdated {\n      id\n      languageCode\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "019d6d6c01481218b2826e9732d7c9b1";

export default node;
