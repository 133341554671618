import { createContext } from 'react';
import { FeatureFlag, SigningData, Tenant, User } from 'types';

export type { FeatureFlag, Tenant, User } from 'types';

type ObjectWithId = {
  id: string;
};

type Genealogy =
  | { active: false }
  | { active: true; firstSpaceIdWithGenealogy: string };

// Preparing data structure for similar usage as Genealogy above
type PrivateVault = { active: false } | { active: true };

export interface SessionContextInterface {
  tenant?: Tenant;
  member?: User;
  isGuest: boolean;
  isCurrentMember: (member: ObjectWithId | null | undefined) => boolean;
  error?: Error;
  isAuthenticated: boolean;
  needsTwoFactor: boolean;
  signOutNow: () => void;
  isReloading: boolean;
  authenticate: () => void;
  featureFlags: readonly FeatureFlag[];
  devtools: {
    isVisible: boolean;
    show: () => void;
    hide: () => void;
    hideFeatureGlow: boolean;
    setHideFeatureGlow: (hideFeatureGlow: boolean) => void;
    toggleFeature: (name: string) => void;
    isAvailable: boolean;
  };
  resetAppState: () => void;
  signingData?: SigningData;
  isTenantUrl: (url: string) => boolean;
  genealogy: Genealogy;
  privateVault: PrivateVault;
  spaceIds: string[];
}

const stub = (): never => {
  throw new Error('This should be replaced on SessionProvider.');
};

const initialSessionContext: SessionContextInterface = {
  isAuthenticated: false,
  needsTwoFactor: false,
  signOutNow: stub,
  authenticate: stub,
  featureFlags: [],
  signingData: null,
  isReloading: true,
  isCurrentMember: () => false,
  devtools: {
    isVisible: false,
    show: () => {},
    hide: () => {},
    hideFeatureGlow: true,
    setHideFeatureGlow: () => {},
    toggleFeature: () => {},
    isAvailable: false,
  },
  resetAppState: () => {},
  isTenantUrl: stub,
  genealogy: { active: false },
  privateVault: { active: false },
  isGuest: false,
  spaceIds: [],
};

export const SessionContext = createContext<SessionContextInterface>(
  initialSessionContext,
);
