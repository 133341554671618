import React, { useCallback, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { Box, Button, Link, Spinner, Stack, VStack } from '@cardboard-ui/react';
import { ArrowCircleRightIcon } from 'components/icons';
import { SIGN_IN_PATH } from 'utils/routes';
import { AuthenticationScreen } from './AuthenticationScreen';
import { authenticatedHttpRequest } from 'utils/http';
import { AuthenticationScreenHeading } from './AuthenticationScreenHeading';
import { Capacitor } from '@capacitor/core';
import { unsubscribeDevice } from 'utils/push';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { twoFactorState } from 'utils/sessionProvider/provider';

export const signOutScreenDescription = {
  name: 'Sign out',
  key: 'sign-out',
  allowCustomContent: true,
  preview: () => <SignOutSkeleton />,
};

export default function SignOut() {
  const { isSigningOut } = useSignOut();

  return <SignOutSkeleton isSigningOut={isSigningOut} />;
}

const SignOutSkeleton = ({ isSigningOut }: { isSigningOut?: boolean }) => {
  return (
    <AuthenticationScreen>
      <Stack>
        <AuthenticationScreenHeading>{t`Sign out`}</AuthenticationScreenHeading>
        <Box>
          <VStack align="center">
            {isSigningOut ? <Spinner /> : false}
            <p>
              {isSigningOut
                ? t`You are being signed out`
                : t`You are signed out`}
            </p>
          </VStack>
        </Box>
        <Box>
          <Button
            as={Link}
            rightIcon={<ArrowCircleRightIcon />}
            to={SIGN_IN_PATH}
            colorScheme="blue"
          >
            {t`Go to sign in`}
          </Button>
        </Box>
      </Stack>
    </AuthenticationScreen>
  );
};

const signOut = () => {
  if (Capacitor.isNativePlatform()) {
    unsubscribeDevice();
    PushNotifications.removeAllListeners();
    App.removeAllListeners();
  }

  twoFactorState.getState().resetRequiresTwoFactor();
  return authenticatedHttpRequest('/auth/sign-out', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: '{}',
  });
};

interface useSignOutProps {
  onSignOutComplete?: () => void;
  autoStart?: boolean;
}

export const useSignOut = ({
  onSignOutComplete,
  autoStart,
}: useSignOutProps = {}) => {
  if (autoStart === undefined) {
    autoStart = true;
  }
  const [isSigningOut, setIsSigningOut] = useState(false);

  useEffect(() => {
    if (autoStart) {
      startSignOut();
    }
  }, [autoStart]);

  const startSignOut = useCallback(
    (callback?: () => void) => {
      setIsSigningOut(true);
      signOut()
        .then(() => {
          callback && callback();
          onSignOutComplete && onSignOutComplete();
        })
        .finally(() => setIsSigningOut(false));
    },
    [setIsSigningOut],
  );

  return { isSigningOut, startSignOut };
};
