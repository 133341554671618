import { Link, LinkProps } from '@cardboard-ui/Link';
import { useTextLinkStyle } from 'apps/TenantApp/theme/components/TextLink';
import React from 'react';

export const TextLink: React.FC<LinkProps & { noColor?: boolean }> = (
  props,
) => {
  const style = useTextLinkStyle();
  return <Link sx={props.noColor ? {} : style} {...props} />;
};
