import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useInterval } from '@cardboard-ui/react';
import { useSession } from 'utils/sessionProvider';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';
import { authenticatedHttpRequest } from 'utils/http';

import useFetchKey from 'hooks/useFetchKey';
import * as Sentry from '@sentry/react';
import { httpSwitchAuthRequest } from '../../SignInFromSwitch';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { App } from '@capacitor/app';
import { useNavigate } from 'react-router-dom';
import isAppDomain from 'utils/isAppDomain';
import { TenantData } from './loadTenantData';
import { useSignInScreenState } from './useSignInScreenState';

export type Tenant = {
  name: string;
  domain: string;
  icon_url: null | string;
  shortcode: string;
};

export type Account = {
  auth_url: string;
  tenant: Tenant;
};

const globalizeAuthUrl = (authUrl: string) => {
  if (window.location.hostname.startsWith('app.')) {
    const path = authUrl.split('/').slice(3).join('/');
    return `https://${window.location.hostname}/${path}`;
  } else {
    return authUrl;
  }
};

export type LoginScreenWithData =
  | { screen: 'start' }
  | { screen: 'forgot-platform' }
  | {
      screen: 'login-with-password-for-app';
      tenant: TenantData;
    }
  | { screen: 'login-with-password' }
  | { screen: 'with-global-token' }
  | { screen: 'with-email-pin'; token: string; login: string }
  | { screen: 'pick-tenant'; accounts: Account[] }
  | { screen: 'select-platform' }
  | { screen: 'reset-password' };

export type LoginScreen = LoginScreenWithData['screen'];

export const AuthLogicContext = createContext<SignInLogicReturnType>({
  onEmailSignInWithPin: () => Promise.reject(),
  onEmailSignIn: () => Promise.reject(),
  onPasswordSignIn: () => Promise.reject(),
  authOptions: [],
  authToken: '',
  resetScreen: () => {},
  openTenant: () => Promise.reject(),
  screen: { screen: 'start' },
  setScreen: () => {},
});

export const useAuthLogic = () => useContext(AuthLogicContext);

export const AuthLogicProvider = ({ children }: { children: ReactNode }) => {
  const isMobileApp = isAppDomain();
  const { isAuthenticated, authenticate } = useSession();
  const [authToken, setAuthToken] = useState<string>();
  const [authTokenFetchKey, resetAuthTokenFetchKey] = useFetchKey();
  const [persistedReturnPath, persistReturnToPath, clearPersistedReturnToPath] =
    usePersistedReturnToPath();
  const [screen, setScreen] = useSignInScreenState();
  const returnToPath = window.location.href.replace(window.location.origin, '');

  const navigate = useNavigate();
  const resetScreen = () => {
    setAuthToken(undefined);
  };

  const fetchExternalAuthToken = useCallback(() => {
    authenticatedHttpRequest('/auth/external-auth-token', {
      method: 'POST',
    }).then(async (r) => {
      try {
        const data = await r.json();
        setAuthToken(data.token);
      } catch (e) {
        Sentry.withScope((s) => {
          s.setLevel('warning');
          s.setTag('http_error', 'fetchExternalAuthToken');
          Sentry.captureException(e);
        });
        setTimeout(resetAuthTokenFetchKey, 5000);
      }
    });
  }, [authTokenFetchKey, setAuthToken]);

  useEffect(fetchExternalAuthToken, []);
  useInterval(resetAuthTokenFetchKey, 45 * 60 * 1000);

  const onPasswordSignIn = useCallback(
    ({
      login,
      password,
      tenantId,
    }: {
      login: string;
      password: string;
      // only needed for mobile app
      tenantId?: string;
    }) =>
      authenticatedHttpRequest('/auth/sign-in', {
        method: 'POST',
        body: JSON.stringify({
          password: password,
          email: login,
          ...(tenantId ? { tenant_id: tenantId } : {}),
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then(async (res) => {
        if (res.status == 200) {
          await res.json();
          authenticate();
          return;
        } else {
          throw new Error('REQUEST_FAILED');
        }
      }),
    [],
  );

  const onEmailSignIn = useCallback(({ login }: { login: string }) => {
    persistReturnToPath(returnToPath);

    const authRoute = isMobileApp
      ? '/auth/request-global-email-auth'
      : '/auth/request-email-auth';
    return authenticatedHttpRequest(authRoute, {
      method: 'POST',
      body: JSON.stringify({
        email: login,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then(async (res) => {
      if (res.status == 200) {
        const data = await res.json();
        if (!data.error) {
          return { token: data.token };
        } else {
          throw new Error(data.status_code);
        }
      } else {
        clearPersistedReturnToPath();
        throw new Error('REQUEST_FAILED');
      }
    });
  }, []);

  const onEmailSignInWithPin = useCallback(
    ({ pin, token }: { pin: string; token: string }) => {
      if (isAuthenticated) {
        return Promise.resolve({ accounts: [] as Account[] });
      }
      const authRoute = isMobileApp
        ? '/auth/global-email-auth-options'
        : '/auth/email-auth';

      return authenticatedHttpRequest(authRoute, {
        method: 'POST',
        body: JSON.stringify({
          key: token,
          pin,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      }).then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          if (isMobileApp) {
            return { accounts: data.accounts as Account[] };
          } else {
            authenticate();
            return { accounts: [] as Account[] };
          }
        } else {
          throw new Error(`${authRoute} REQUEST_FAILED`);
        }
      });
    },
    [isAuthenticated, authenticate],
  );

  const { resetAppState } = useSession();
  const isApp =
    window.location.hostname.startsWith('app.') ||
    window.location.hostname.startsWith('auth.');

  // taken over from old code
  // TODO We would love to open a new tab here, however we have to make some difficult choices.
  //   The `window.open` technique doesn't reliably work as the `open` event and initial click
  //   are no longer connected to each other. The Browser will kill the pop-up.
  // We could resolve this by requesting the `switchUrl` beforehand, but hen we need to keep refreshing it while the component is visible.
  // We can change this in a 2 step process for the user, where the first step fetches the link, and the other then opens in new tab or not.
  //   This is less nice for the user as they can have to click twice.
  // So for now we just open in the same tab.
  const openTenant = (account: Account) => {
    const authUrl = account.auth_url;
    return authenticatedHttpRequest(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }).then(async (res) => {
      if (res.status == 200) {
        const responseJson = await res.json();
        const switchUrl = globalizeAuthUrl(responseJson.switch_auth_link);
        const token = switchUrl.split('/').pop();
        if (token) {
          if (isApp) {
            httpSwitchAuthRequest(token).then(async (res) => {
              if (res.status == 200) {
                /*

                        // We could do a full browser reload, but the native app doesn't like that
                        // window.location.assign(TENANT_DEFAULT_LANDING_PATH

                        // Here we trigger a reload of the session, but that may be problematic
                        // onClose(); // This will ensure we close the modal after the refresh
                        // authenticate();
                      */

                // Ideally we would just reset the entire application state
                // onClose(); // This will ensure we close the modal after the refresh
                if (Capacitor.isNativePlatform()) {
                  await PushNotifications.removeAllListeners();
                  await App.removeAllListeners();
                }
                resetAppState();
                navigate(persistedReturnPath ?? '/');
              }
            });
          } else {
            // eslint-disable-next-line no-restricted-properties
            window.location.assign(switchUrl);
          }
        } else {
          throw new Error(
            'openTenant http call did not include token response',
          );
        }
      } else {
        throw new Error('openTenant http call failed');
      }
    });
  };

  return (
    <AuthLogicContext.Provider
      value={{
        onEmailSignInWithPin,
        onEmailSignIn,
        onPasswordSignIn,
        authOptions: window.preload_AuthOptions || [],
        authToken,
        resetScreen,
        openTenant,
        screen,
        setScreen,
      }}
    >
      {children}
    </AuthLogicContext.Provider>
  );
};

export interface SignInLogicReturnType {
  onEmailSignInWithPin(args: {
    pin: string;
    token: string;
  }): Promise<{ accounts: Account[] }>;
  onEmailSignIn(args: { login: string }): Promise<{ token: string }>;
  onPasswordSignIn(args: {
    login: string;
    password: string;
    tenantId?: string;
  }): Promise<void>;
  authOptions: { id: string; url?: string }[];
  authToken: string | undefined;
  resetScreen: () => void;
  openTenant(account: Account): Promise<void>;
  screen: LoginScreenWithData;
  setScreen: (screen: LoginScreenWithData) => void;
}

declare global {
  interface Window {
    reloadAfterAuthClosed: (targetUrl: string) => void;
    preload_AuthOptions?: { id: string; url?: string }[];
  }
}
