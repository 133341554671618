/**
 * @generated SignedSource<<3e9c6993e81fad466622c3ce0f6e1dbb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useVaultInfoQuery$variables = {};
export type useVaultInfoQuery$data = {
  readonly sessionInfo: {
    readonly canManageVaults: {
      readonly value: boolean;
    };
    readonly member: {
      readonly vaults: ReadonlyArray<{
        readonly id: string;
      }>;
    } | null;
  };
};
export type useVaultInfoQuery = {
  response: useVaultInfoQuery$data;
  variables: useVaultInfoQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Vault",
  "kind": "LinkedField",
  "name": "vaults",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "AuthorizationResult",
  "kind": "LinkedField",
  "name": "canManageVaults",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useVaultInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useVaultInfoQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SessionInfo",
        "kind": "LinkedField",
        "name": "sessionInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Member",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c8cdf5c298800e822543a4080f259fe",
    "id": null,
    "metadata": {},
    "name": "useVaultInfoQuery",
    "operationKind": "query",
    "text": "query useVaultInfoQuery {\n  sessionInfo {\n    member {\n      vaults {\n        id\n      }\n      id\n    }\n    canManageVaults {\n      value\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "76d5ba6b981d62593d9f4c6b8916ba65";

export default node;
