import { useNavigate, useSearchParams } from 'react-router-dom';

interface OnOpenOptions {
  [key: string]: string | undefined;
}

interface UseOpenWithSearchParamOptions {
  removeParamsOnClose?: string[];
}

export const useOpenWithSearchParam = (
  paramName: string,
  { removeParamsOnClose }: UseOpenWithSearchParamOptions = {},
) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const currentValue = params.get(paramName) || undefined;

  const searchGenerator = (paramValue: string, otherParams: OnOpenOptions) => {
    params.set(paramName, paramValue);
    if (otherParams) {
      Object.keys(otherParams).forEach((key) => {
        if (otherParams[key] === undefined) {
          params.delete(key);
        } else {
          params.set(key, otherParams[key] as string);
        }
      });
    }
    return params.toString();
  };

  return {
    onOpen: (paramValue: string, openOptions: OnOpenOptions = {}) => {
      navigate({ search: searchGenerator(paramValue, openOptions) });
    },
    openTo: (paramValue: string, openOptions: OnOpenOptions = {}) =>
      `?${searchGenerator(paramValue, openOptions)}`,
    isOpen: !!currentValue,
    paramValue: currentValue,
    onClose: () => {
      [...(removeParamsOnClose || []), paramName].forEach((key) =>
        params.delete(key),
      );
      navigate({ search: params.toString() });
    },
  };
};
