import { createContext } from 'react';

export type FolderViewMode = 'list' | 'grid' | 'table' | 'inline';

export function isFolderViewMode(viewMode: any): viewMode is FolderViewMode {
  switch (viewMode) {
    case 'list':
    case 'grid':
    case 'table':
    case 'inline':
      return true;
    default:
      return false;
  }
}

interface FolderViewModeContextInterface {
  viewMode: FolderViewMode;
  changeViewMode(viewMode: FolderViewMode): void;
}

const dummy = () => {
  throw new Error('No FolderViewModeProvider found.');
};

const initialFolderViewModeContext: FolderViewModeContextInterface = {
  viewMode: 'table',
  changeViewMode: dummy,
};

export const FolderViewModeContext =
  createContext<FolderViewModeContextInterface>(initialFolderViewModeContext);
