import {
  arSA,
  de,
  enGB,
  enUS,
  es,
  fr,
  it,
  nl,
  pt,
  ptBR,
} from 'date-fns/locale';
import { AvailableLocale } from '.';

const DATE_FNS_MAP = {
  en: enUS,
  'en-GB': enGB,
  fr: fr,
  ar: arSA,
  de,
  nl,
  pt,
  'pt-BR': ptBR,
  es,
  it,
} as { [key: string]: typeof enGB };

export const dateFnsLocale = (locale: AvailableLocale) =>
  DATE_FNS_MAP[locale] || enGB;
