import React, { FC } from 'react';
import { Button, ButtonProps, forwardRef } from '@cardboard-ui/react';
import { PlusIcon } from 'components/icons';

export const CreateButton: FC<ButtonProps> = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <Button
      ref={ref}
      colorScheme="green"
      size="md"
      leftIcon={<PlusIcon />}
      {...props}
    />
  )
);
