import {
  Box,
  Button,
  Collapse,
  Divider,
  forwardRef,
  HStack,
  Spinner,
  Stack,
  Text,
  TokenInput,
  useInterval,
  useToast,
  VStack,
} from '@cardboard-ui/react';
import { t, Trans } from '@lingui/macro';
import { AuthenticationScreen } from 'apps/TenantApp/screens/authentication/AuthenticationScreen';
import { AuthenticationScreenHeading } from 'apps/TenantApp/screens/authentication/AuthenticationScreenHeading';
import AuthButton from 'apps/TenantApp/screens/authentication/AuthButton';
import { Form } from 'components/Form';
import { Input } from 'components/Form/Input';
import {
  KeyIcon,
  NavigateBackIcon,
  PaperPlaneIcon,
  SuccessIcon,
  WarningIcon,
} from 'components/icons';
import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { authenticatedHttpRequest } from 'utils/http';
import {
  Account,
  AuthCard,
  globalizeAuthUrl,
} from '../../../SignInWithTokenAccountSelect';
import { Capacitor } from '@capacitor/core';
import { useNavigate } from 'react-router-dom';
import useFetchKey from 'hooks/useFetchKey';
import * as Sentry from '@sentry/react';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';

declare global {
  interface Window {
    reloadAfterAuthClosed: (targetUrl: string) => void;
    preload_AuthOptions?: { id: string; url?: string }[];
  }
}

interface GlobalSignInProps {}

interface FormFields {
  login: string;
}

export const GlobalSignIn = forwardRef<GlobalSignInProps, 'div'>(({}, ref) => {
  const [emailRequesting, setEmailRequesting] = useState(false);
  const [emailRequested, setEmailRequested] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [emailAddressRequested, setEmailAddressRequested] = useState<string>();
  const [signinError, setSigninError] = useState('');
  const { register, handleSubmit, formState } = useForm<FormFields>();
  const [authToken, setAuthToken] = useState<string>();
  const [authTokenFetchKey, resetAuthTokenFetchKey] = useFetchKey();
  const authOptions = window.preload_AuthOptions || [];
  const externalAuthOptions = authOptions.filter(({ url }) => !!url) as {
    id: string;
    url: string;
  }[];
  const [emailSignInToken, setEmailSignInToken] = useState<
    'string' | undefined
  >();
  const emailSignInMode = emailSignInToken === undefined ? 'link' : 'token';
  const [accounts, setAccounts] = useState<Account[] | undefined>(undefined);
  const [invalidToken, setInvalidToken] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const email = emailAddressRequested;

  const resetScreen = useCallback(() => {
    startTransition(() => {
      setEmailRequested(false);
      setEmailRequesting(false);
      setSigninError('');
      setEmailAddressRequested(undefined);
      setAuthToken(undefined);
      setEmailSignInToken(undefined);
      setIsAuthenticating(false);
    });
  }, []);

  const onSubmit = useCallback(({ login }: FormFields) => {
    setEmailRequesting(true);
    setSigninError('');

    authenticatedHttpRequest('/auth/request-global-email-auth', {
      method: 'POST',
      body: JSON.stringify({
        email: login,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          if (!data.error) {
            setEmailRequested(true);
            setEmailAddressRequested(login);
            setEmailSignInToken(data.token);
          } else {
            setSigninError(
              t`We already handled a sign-in request for this email. Please try again in a few minutes`,
            );
          }
        } else {
          setSigninError(t`Email link request failed`);
        }
      })
      .finally(() => {
        setEmailRequesting(false);
      });
  }, []);

  const onEmailSignInWithPin = useCallback(
    ({ pin }: { pin: string }) => {
      setIsAuthenticating(true);
      setSigninError('');
      authenticatedHttpRequest('/auth/global-email-auth-options', {
        method: 'POST',
        body: JSON.stringify({
          key: emailSignInToken,
          pin,
        }),
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
        .then(async (res) => {
          if (res.status == 200) {
            const data = await res.json();
            setAccounts(data.accounts);
          } else if (res.status == 404) {
            setInvalidToken(true);
            toast({
              title: t`The pin provided is not correct`,
              status: 'error',
              description: t`If this error persists, try requesting the sign-in email again.`,
            });
          }
        })
        .finally(() => {
          setIsAuthenticating(false);
        });
    },
    [emailSignInToken],
  );

  const fetchExternalAuthToken = useCallback(() => {
    authenticatedHttpRequest('/auth/external-auth-token', {
      method: 'POST',
    }).then(async (r) => {
      try {
        const data = await r.json();
        setAuthToken(data.token);
      } catch (e) {
        Sentry.withScope((s) => {
          s.setLevel('warning');
          s.setTag('http_error', 'fetchExternalAuthToken');
          Sentry.captureException(e);
        });
        setTimeout(resetAuthTokenFetchKey, 5000);
      }
    });
  }, [authTokenFetchKey, setAuthToken]);

  useEffectOnceWhen(true, fetchExternalAuthToken);
  useInterval(resetAuthTokenFetchKey, 45 * 60 * 1000);

  useEffect(() => {
    if (accounts && accounts.length === 1) {
      if (Capacitor.isNativePlatform()) {
        const url = new URL(accounts[0].auth_url);
        navigate(url.href.replace(url.origin, ''));
      } else {
        // eslint-disable-next-line no-restricted-properties
        window.location.assign(globalizeAuthUrl(accounts[0].auth_url));
      }
    }
  }, [accounts]);

  return (
    <AuthenticationScreen ref={ref}>
      <Stack>
        <AuthenticationScreenHeading>
          <HStack pos="relative" justify="center">
            <span>{t`Sign in`}</span>
          </HStack>
        </AuthenticationScreenHeading>
        <Box>
          <Collapse
            in={accounts && accounts.length > 1}
            animateOpacity
            unmountOnExit
          >
            {accounts &&
              accounts.map(({ auth_url, tenant }) => (
                <AuthCard
                  key={tenant.domain}
                  tenant={tenant}
                  authUrl={auth_url}
                />
              ))}
          </Collapse>
          <Collapse
            in={emailRequested && accounts === undefined}
            animateOpacity
            unmountOnExit
          >
            <VStack pt={1}>
              <PaperPlaneIcon size="4x" />
              {signinError ? (
                <Box>
                  <WarningIcon />
                  {signinError}
                </Box>
              ) : emailRequesting ? (
                <Spinner size="xs" />
              ) : (
                <Box data-qa="email-sent-confirmation" color="green.500">
                  <SuccessIcon />
                </Box>
              )}
              {emailSignInMode === 'token' &&
                (isAuthenticating ? (
                  <Spinner />
                ) : (
                  <VStack data-qa="email-sent-confirmation" py={4}>
                    <Box>{t`Enter the code from the email below:`}</Box>
                    <HStack>
                      <TokenInput
                        otp
                        autoFocus
                        expectedLength={6}
                        onComplete={(pin) => onEmailSignInWithPin({ pin })}
                      />
                    </HStack>
                  </VStack>
                ))}
              <small>
                <Trans>
                  Email sent to <strong>{email}</strong>. It can take a minute
                  before it arrives. If you didn't receive an email, please make
                  sure you've entered the correct address.
                </Trans>
              </small>
              <Button variant="link" onClick={resetScreen}>
                <NavigateBackIcon /> {t`Back`}
              </Button>
            </VStack>
          </Collapse>
          <Collapse in={!emailRequested} animateOpacity>
            <VStack alignItems="stretch">
              <Form
                CTASubmitName={t`Send Sign-in link`}
                onSubmit={handleSubmit(onSubmit)}
                isSubmitting={emailRequesting}
              >
                <Input
                  formid="email"
                  label="Your email"
                  autoComplete="username"
                  errorMessage={signinError || formState.errors.login?.message}
                  type="email"
                  {...register('login', {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t`Invalid email address`,
                    },
                  })}
                  required
                />
              </Form>
              {externalAuthOptions.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  paddingY={4}
                  gridGap={4}
                >
                  <Divider borderColor="black" />
                  <Text flexGrow={1} fontSize="sm" whiteSpace="nowrap">
                    <KeyIcon /> {t`Other Sign-in Options`}
                  </Text>
                  <Divider borderColor="black" />
                </Box>
              )}
              {externalAuthOptions.map(({ url, id }) => (
                <AuthButton key={id} id={id} token={authToken} href={url} />
              ))}
            </VStack>
          </Collapse>
        </Box>
        <Box />
      </Stack>
    </AuthenticationScreen>
  );
});

const GlobalSignInWithDisable = () =>
  (window.preload_AuthOptions || []).length > 0 ? (
    <GlobalSignIn />
  ) : (
    <GlobalSignInDisabled />
  );

export default GlobalSignInWithDisable;

const GlobalSignInDisabled = forwardRef<GlobalSignInProps, 'div'>(({}, ref) => (
  <AuthenticationScreen ref={ref}>
    <Stack>
      <AuthenticationScreenHeading>
        <HStack>
          <span>{t`Sign in`}</span>
        </HStack>
      </AuthenticationScreenHeading>
      <Box>{t`Global sign-in is currently not available.`}</Box>
    </Stack>
  </AuthenticationScreen>
));
