import React, { useCallback } from 'react';
import { PasswordlessLogin } from '../../redesigned/PasswordlessLogin';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { GoogleButton } from '..';
import { useAuthLogic } from '../utils/SignInLogic';
import isAppDomain from 'utils/isAppDomain';
import { FindTenantByMail } from '../../redesigned/FindTenantByMail';

const isMobileApp = isAppDomain();

const SignIn = ({
  mode = 'sign-in',
}: {
  mode?: 'sign-in' | 'find-by-email';
}) => {
  const { setScreen, onEmailSignIn } = useAuthLogic();
  const toast = useToast();

  const handleError = useCallback(
    (statusCode: string) => {
      switch (statusCode) {
        case 'INVALID_EMAIL':
          toast({
            title: t`Invalid email address`,
            description: t`Please enter a valid email address.`,
            status: 'error',
            isClosable: true,
            duration: 5000,
          });
          break;
        case 'RATE_LIMIT_EXCEEDED':
          toast({
            title: t`Rate limit exceeded`,
            description: t`We already handled a sign-in request for this email. Please try again in a few minutes`,
            status: 'error',
            isClosable: true,
            duration: 5000,
          });
          break;
        default:
          toast({
            title: t`Request failed`,
            description: t`An error occurred while trying to sign in`,
            status: 'error',
            isClosable: true,
            duration: 5000,
          });
          break;
      }
    },
    [toast],
  );

  const handleSubmit = useCallback(
    (login: string) =>
      onEmailSignIn({ login })
        .then(({ token }) => {
          setScreen({ screen: 'with-email-pin', token, login });
        })
        .catch((error) => {
          handleError(error.statusCode);
        }),
    [setScreen, handleError],
  );

  const onClickSignInViaPassword = useCallback(() => {
    if (isMobileApp) {
      setScreen({ screen: 'select-platform' });
    } else {
      setScreen({ screen: 'login-with-password' });
    }
  }, [setScreen]);

  if (mode === 'find-by-email') {
    return (
      <FindTenantByMail
        onConfirmMail={handleSubmit}
        ExternalAuth={GoogleButton}
      />
    );
  }

  return (
    <PasswordlessLogin
      onClickSignInViaPassword={onClickSignInViaPassword}
      onLoginWithOnlyMail={handleSubmit}
      ExternalAuth={GoogleButton}
    />
  );
};

export default SignIn;
