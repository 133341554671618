import { registerPlugin } from '@capacitor/core';

const ResourceLoadingPlugin = registerPlugin<ImagePluginInterface>(
  'ResourceLoading',
  {},
);

export interface ImagePluginInterface {
  registerResourceLoader(): Promise<void>;
}

export { ResourceLoadingPlugin };
