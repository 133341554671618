import React, { useState } from 'react';
import {
  Button,
  forwardRef,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useFormControl,
} from '@cardboard-ui/react';
import { t } from '@lingui/macro';

export interface PasswordInputProps extends InputProps {
  autoComplete: 'current-password' | 'new-password';
}

export const PasswordInput = forwardRef<PasswordInputProps, 'div'>(
  (ownProps, ref) => {
    const [showPassword, setShowPassword] = useState(false),
      togglePasswordVisibility = () => setShowPassword((s) => !s),
      input = useFormControl<HTMLInputElement>(ownProps);

    return (
      <InputGroup ref={ref}>
        <Input
          pr="4.5rem"
          type={showPassword ? 'text' : 'password'}
          {...ownProps}
        />
        <InputRightElement width="4.5rem">
          <Button
            h="1.75rem"
            size="sm"
            onClick={togglePasswordVisibility}
            isDisabled={input.disabled}
          >
            {showPassword ? t`Hide` : t`Show`}
          </Button>
        </InputRightElement>
      </InputGroup>
    );
  },
);
