import { Capacitor } from '@capacitor/core';
import {
  Avatar,
  Box,
  forwardRef,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { t, Trans } from '@lingui/macro';
import { AuthenticationScreen } from 'apps/TenantApp/screens/authentication/AuthenticationScreen';
import { AuthenticationScreenHeading } from 'apps/TenantApp/screens/authentication/AuthenticationScreenHeading';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authenticatedHttpRequest } from 'utils/http';
import { SIGN_IN_PATH, TOKEN_PARAM } from 'utils/routes';
import useSafeParam from 'utils/routes/useSafeParam';
import * as Sentry from '@sentry/react';
import { Link } from '@cardboard-ui/react';
import { usePersistedReturnToPath } from 'hooks/useReturnToPath';

export type Tenant = {
  name: string;
  domain: string;
  icon_url: null | string;
  shortcode: string;
};

export type Account = {
  auth_url: string;
  tenant: Tenant;
};

export const SignInWithTokenAccountSelect = forwardRef<{}, 'div'>(({}, ref) => {
  const token = useSafeParam(TOKEN_PARAM);
  const [params] = useSearchParams();
  const [accounts, setAccounts] = useState<Account[] | undefined>(undefined);
  const [invalidToken, setInvalidToken] = useState(false);
  const [isFailedRequest, setIsFailedRequest] = useState(false);
  const navigate = useNavigate();
  const [, persistReturnToPath] = usePersistedReturnToPath();

  useEffect(() => {
    const origin = params.get('origin');
    if (origin) {
      const url = new URL(origin);
      persistReturnToPath(url.href.replace(url.origin, ''));
    }
  }, [params]);

  useEffect(() => {
    authenticatedHttpRequest('/auth/global-email-auth-options', {
      method: 'POST',
      body: JSON.stringify({
        key: token,
      }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    })
      .then(async (res) => {
        if (res.status == 200) {
          const data = await res.json();
          setAccounts(data.accounts);
        } else if (res.status == 404) {
          setInvalidToken(true);
        } else {
          setIsFailedRequest(true);
          Sentry.withScope((s) => {
            s.setLevel('error');
            s.setExtra('http_status', res.status);
            Sentry.captureException(new Error('Error signing in with token'));
          });
        }
      })
      .catch((e) => {
        setIsFailedRequest(true);
        Sentry.captureException(e);
      });
  }, [token]);

  useEffect(() => {
    if (accounts && accounts.length === 1) {
      if (Capacitor.isNativePlatform()) {
        const url = new URL(accounts[0].auth_url);
        navigate(url.href.replace(url.origin, ''));
      } else {
        // eslint-disable-next-line no-restricted-properties
        window.location.assign(globalizeAuthUrl(accounts[0].auth_url));
      }
    }
  }, [accounts]);

  if (invalidToken) {
    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>
            <HStack>
              <span>{t`Invalid token`}</span>
            </HStack>
          </AuthenticationScreenHeading>
          <Box>
            <Trans>
              The sign in link used here is no longer valid. <br />
              You can try to sign in again by visiting the{' '}
              <a href={SIGN_IN_PATH}>sign in page</a>.
            </Trans>
          </Box>
        </Stack>
      </AuthenticationScreen>
    );
  }

  if (isFailedRequest) {
    return (
      <AuthenticationScreen ref={ref}>
        <Stack>
          <AuthenticationScreenHeading>
            <HStack>
              <span>{t`Error`}</span>
            </HStack>
          </AuthenticationScreenHeading>
          <Box>
            <Trans>
              An error occurred while signing in. <br />
              Please <Link to={SIGN_IN_PATH}>try again</Link>.
            </Trans>
          </Box>
        </Stack>
      </AuthenticationScreen>
    );
  }

  return (
    <AuthenticationScreen ref={ref}>
      <Stack>
        <AuthenticationScreenHeading>
          <HStack>
            <span>{t`Sign in`}</span>
          </HStack>
        </AuthenticationScreenHeading>
        <Box>
          {accounts && accounts.length > 1 ? (
            accounts.map(({ auth_url, tenant }) => (
              <AuthCard
                key={tenant.domain}
                tenant={tenant}
                authUrl={globalizeAuthUrl(auth_url)}
              />
            ))
          ) : (
            <Spinner />
          )}
        </Box>
      </Stack>
    </AuthenticationScreen>
  );
});

export const globalizeAuthUrl = (authUrl: string) => {
  if (window.location.hostname.startsWith('app.')) {
    const path = authUrl.split('/').slice(3).join('/');
    return `https://${window.location.hostname}/${path}`;
  } else {
    return authUrl;
  }
};

export const AuthCard = ({
  tenant,
  authUrl,
}: {
  tenant: Tenant;
  authUrl: string;
}) => {
  const navigate = useNavigate();

  return (
    <HStack
      as="a"
      href={globalizeAuthUrl(authUrl)}
      my={1}
      p={1}
      _hover={{ bg: 'gray.200' }}
      onClick={(e) => {
        if (Capacitor.isNativePlatform()) {
          e.preventDefault();
          const url = new URL(authUrl);
          navigate(url.href.replace(url.origin, ''));
        }
      }}
    >
      <Avatar
        borderWidth="2px"
        borderRadius="md"
        borderColor="white"
        borderStyle="solid"
        boxSize="50px"
        bg={tenant.icon_url ? 'transparent' : undefined}
        name={tenant.name}
        src={tenant.icon_url || undefined}
      />
      <VStack align="start" spacing={0}>
        <Heading as="span" size="md">
          {tenant.name}
        </Heading>
        <Text fontSize="xs">{tenant.domain}</Text>
      </VStack>
    </HStack>
  );
};

export default SignInWithTokenAccountSelect;
