import React from 'react';
import { CenteredSpinner } from 'components/CenteredSpinner';
import { Screen } from 'layouts/Screen';

export default function LoadingScreen() {
  return (
    <Screen>
      <CenteredSpinner debugName="Loading Screen" />
    </Screen>
  );
}
