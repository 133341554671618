/**
 * @generated SignedSource<<d8291238b3f87e5a50fe969ac81c2006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FileInput = {
  uploadToken: string;
};
export type useThemeEditing_addAttachmentsToTheme_Mutation$variables = {
  attachments: ReadonlyArray<FileInput>;
  themeId: string;
};
export type useThemeEditing_addAttachmentsToTheme_Mutation$data = {
  readonly addAttachmentsToTheme: {
    readonly attachmentsAdded: ReadonlyArray<{
      readonly __typename: "File";
      readonly id: string;
      readonly mimeType: string;
      readonly name: string;
      readonly size: Int;
      readonly url: URIString;
    }>;
    readonly themeUpdated: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"useThemeEditing_theme">;
    };
  } | null;
};
export type useThemeEditing_addAttachmentsToTheme_Mutation = {
  response: useThemeEditing_addAttachmentsToTheme_Mutation$data;
  variables: useThemeEditing_addAttachmentsToTheme_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attachments"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "themeId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "attachments",
        "variableName": "attachments"
      },
      {
        "kind": "Variable",
        "name": "themeId",
        "variableName": "themeId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "attachmentsAdded",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v3/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mimeType",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useThemeEditing_addAttachmentsToTheme_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ThemesAddAttachmentsMutationPayload",
        "kind": "LinkedField",
        "name": "addAttachmentsToTheme",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Themes__Theme",
            "kind": "LinkedField",
            "name": "themeUpdated",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useThemeEditing_theme"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useThemeEditing_addAttachmentsToTheme_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ThemesAddAttachmentsMutationPayload",
        "kind": "LinkedField",
        "name": "addAttachmentsToTheme",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Themes__Theme",
            "kind": "LinkedField",
            "name": "themeUpdated",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "variables",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "colorLight",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "light",
                        "storageKey": null
                      },
                      {
                        "alias": "colorDark",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dark",
                        "storageKey": null
                      }
                    ],
                    "type": "Themes__Color",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rawValue",
                        "storageKey": null
                      }
                    ],
                    "type": "Themes__Content",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": "imageLight",
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "light",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": "imageDark",
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "dark",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "type": "Themes__Image",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "47edab62409724ad74ac31a0dd1996d7",
    "id": null,
    "metadata": {},
    "name": "useThemeEditing_addAttachmentsToTheme_Mutation",
    "operationKind": "mutation",
    "text": "mutation useThemeEditing_addAttachmentsToTheme_Mutation(\n  $themeId: ID!\n  $attachments: [FileInput!]!\n) {\n  addAttachmentsToTheme(input: {themeId: $themeId, attachments: $attachments}) {\n    themeUpdated {\n      id\n      ...useThemeEditing_theme\n    }\n    attachmentsAdded {\n      __typename\n      id\n      name\n      url\n      size\n      mimeType\n    }\n  }\n}\n\nfragment useThemeEditing_theme on Themes__Theme {\n  id\n  variables {\n    __typename\n    name\n    ... on Themes__Color {\n      colorLight: light\n      colorDark: dark\n    }\n    ... on Themes__Content {\n      rawValue\n    }\n    ... on Themes__Image {\n      imageLight: light {\n        id\n        url\n      }\n      imageDark: dark {\n        id\n        url\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4b1a455cc2f5c119f9c9a643525eced";

export default node;
