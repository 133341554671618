import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
  useSafeRelay,
} from 'utils/graphClient';
import {
  useVaultInfoQuery,
  useVaultInfoQuery$variables,
} from './__generated__/useVaultInfoQuery.graphql';

export type RouteReference = PreloadedQuery<useVaultInfoQuery>;

const VaultInfoQuery = graphql`
  query useVaultInfoQuery {
    sessionInfo {
      member {
        vaults {
          id
        }
      }
      canManageVaults {
        value
      }
    }
  }
`;

export const useVaultInfoQueryLoader = () => {
  return useQueryLoader<useVaultInfoQuery>(VaultInfoQuery);
};

export const usePreloadedVaultInfo = (routeRef: RouteReference) => {
  return usePreloadedQuery<useVaultInfoQuery>(VaultInfoQuery, routeRef);
};

export const useLazyLoadVaultInfoQuery = (
  props?: useVaultInfoQuery$variables,
) => {
  const { useLazyLoadQuery } = useSafeRelay();

  return useLazyLoadQuery<useVaultInfoQuery>(VaultInfoQuery, props || {});
};
