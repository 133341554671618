/* eslint-disable no-restricted-imports */

import {
  Spinner as ChakraSpinner,
  forwardRef,
  SpinnerProps,
} from '@chakra-ui/react';
import React from 'react';

export const Spinner = forwardRef<SpinnerProps, 'input'>((props, ref) => {
  return <ChakraSpinner data-qa="spinner" ref={ref} {...props} />;
});
