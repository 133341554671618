import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

type UnreadNotificationsContextType = {
  hasUnreadNotifications: boolean;
  setUnreadNotifications: (state: boolean) => void;
};

const initialUnreadNotificationsContext: UnreadNotificationsContextType = {
  hasUnreadNotifications: false,
  setUnreadNotifications: () => {},
};

export const useUnreadNotificationsContext = () =>
  useContext(UnreadNotificationsContext);

export const UnreadNotificationsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [hasUnreadNotifications, setUnreadNotifications] = useState(
    initialUnreadNotificationsContext.hasUnreadNotifications,
  );

  const providerState = {
    hasUnreadNotifications,
    setUnreadNotifications,
  };

  return (
    <UnreadNotificationsContext.Provider value={providerState}>
      {children}
    </UnreadNotificationsContext.Provider>
  );
};

const UnreadNotificationsContext =
  createContext<UnreadNotificationsContextType>(
    initialUnreadNotificationsContext,
  );
