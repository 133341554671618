import { getCsrfToken } from 'utils/getCsrfToken';
import { authenticatedHttpRequest } from 'utils/http';
import { nullable, object, string } from 'checkeasy';

const responseValidator = object({
  data: object({
    sessionInfo: object({
      tenant: object({
        domain: string(),
        icon: nullable(
          object({
            url: string(),
          }),
        ),
        name: string(),
        id: string(),
      }),
    }),
  }),
});

export type TenantData = ReturnType<
  typeof responseValidator
>['data']['sessionInfo']['tenant'];

// this loads tenant data for a given tenant if you are not logged in
// and you load via app.*
export const loadTenantDataFor = async (tenantSubDomain: string) => {
  const token = await getCsrfToken();

  const domain = window.location.hostname.split('.').slice(1).join('.');
  const data = await authenticatedHttpRequest('/graph', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'Tenant-Domain': `${tenantSubDomain}.${domain}`,
      'X-CSRF-Token': token,
    },
    body: JSON.stringify({
      query: `
        query loadTenantDataFor_Query {
          sessionInfo {
            tenant {
              id
              name
              domain
              icon {
                url
              }
            }
          }
        }
      `,
      variables: {},
    }),
  });

  return data.json().then((jsonData) => {
    const response = responseValidator(jsonData, 'loadTenantDataFor_Query');
    return response.data.sessionInfo.tenant;
  });
};
