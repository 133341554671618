const appSubDomains = ['app', 'new', 'auth'].map((domain) => `${domain}.`);

const isAppDomain = (domain: string = window.location.hostname) => {
  if (domain.split('.').length < 3) {
    return false;
  }

  return !!appSubDomains.find((subdomain: string) =>
    domain.startsWith(subdomain),
  );
};

export default isAppDomain;
