import { useEffect, useState } from 'react';
import { ConnectionStatus, Network } from '@capacitor/network';

export function useNetworkStatus() {
  const [networkStatus, setNetworkStatus] = useState<ConnectionStatus | null>(
    null,
  );

  useEffect(() => {
    Network.addListener('networkStatusChange', setNetworkStatus);

    return () => {
      Network.removeAllListeners();
    };
  }, []);

  return networkStatus;
}
