import React, { FC, PropsWithChildren } from 'react';
import { useSession } from './sessionProvider';
import { CenteredSpinner } from 'components/CenteredSpinner';
import SignInModal from 'apps/TenantApp/screens/authentication/SignIn';
import LegacySignInModal from 'apps/TenantApp/screens/authentication/SignIn/legacy/components/SignInWithTenant';
import { useFlag } from '@unleash/proxy-client-react';

export const RequireAuthentication: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, isReloading } = useSession();

  if (isReloading) {
    return <CenteredSpinner debugName="RequireAuthentication/isReloading" />;
  }

  if (!isAuthenticated) {
    return <AuthenticationRequired />;
  }

  return <>{children}</>;
};

const AuthenticationRequired = () => {
  const isNewLoginEnabled = useFlag('new_login');
  return isNewLoginEnabled ? <SignInModal /> : <LegacySignInModal />;
};
