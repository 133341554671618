import React from 'react';
import { t } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Heading,
  Input,
  Stack,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';
import { useForm } from 'react-hook-form';
import * as EmailValidator from 'email-validator';

type FindTenantByMailProps = {
  onConfirmMail: (email: string) => void;
  ExternalAuth: React.ElementType;
};

export const FindTenantByMail = ({
  onConfirmMail,
  ExternalAuth,
}: FindTenantByMailProps) => {
  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const onSubmit = handleSubmit(({ email }) => {
    return onConfirmMail(email);
  });

  return (
    <AuthenticationScreen>
      <Stack spacing={4}>
        <VStack>
          <TrustedFamilyLogo />
          <Heading
            fontWeight="bold"
            fontSize="2xl"
          >{t`Let's find your platform`}</Heading>
        </VStack>
        <form onSubmit={onSubmit}>
          <Input
            autoFocus
            {...register('email', {
              required: true,
              validate: {
                email: (value) => EmailValidator.validate(value),
              },
            })}
            type="email"
            placeholder={t`name@your-email.com`}
          />

          <Button
            variant="solid"
            colorScheme="blue"
            isDisabled={!formState.isValid}
            isLoading={formState.isSubmitting}
            type="submit"
            w="full"
            mt={2}
          >{t`Continue with email`}</Button>
        </form>
        <Box position="relative" paddingY={4} w="full">
          <Divider />
          <AbsoluteCenter
            px={4}
            bg={useColorModeValue('white', 'gray.700')}
            color="gray.500"
          >{t`OR`}</AbsoluteCenter>
        </Box>
        <ExternalAuth />
      </Stack>
    </AuthenticationScreen>
  );
};

const preventDefault =
  (callback: (event: React.MouseEvent) => unknown) =>
  (event: React.MouseEvent) => {
    event.preventDefault();
    callback(event);
  };
