import React, { FC } from 'react';
import { Button as ChakraButton, forwardRef } from '@cardboard-ui/react';

export interface ButtonProps {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const Button: FC<ButtonProps> = forwardRef<ButtonProps, 'button'>(
  (props, ref) => (
    <ChakraButton ref={ref} colorScheme="blue" size="md" {...props} />
  )
);
