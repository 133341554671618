import { Capacitor } from '@capacitor/core';
import { ButtonProps, forwardRef } from '@cardboard-ui/react';
import React from 'react';
import GoogleButton from './components/GoogleButton';
import OAuthInitForm from './components/OAuthInitForm';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { httpRequest } from 'utils/http';
import { useNavigate } from 'react-router-dom';

// Right now we only support one type of button, but we are ready for more.
const Buttons = {
  google: GoogleButton,
};

type SupportedButtonType = keyof typeof Buttons;

interface AuthButtonProps extends ButtonProps {
  id: SupportedButtonType;
  href: string;
  token: string | undefined;
}

export const AuthButton = forwardRef<AuthButtonProps, 'div'>(
  ({ href, id, token, ...props }, ref) => {
    const navigate = useNavigate();
    const ButtonComponent = Buttons[id];

    // For OAuth we need to create a real POST request, possibly in a pop-up.
    // This is handled by the OAuthInitForm.
    // The submit button is `ButtonComponent` rendered outside the form but linked through the `form` attribute.
    // We render the button outside so the form component does not affect how it is displayed.
    return (
      <>
        <OAuthInitForm id={id} href={href} token={token} />
        {Capacitor.isNativePlatform() ? (
          <ButtonComponent
            isDisabled={!token}
            ref={ref}
            onClick={async () => {
              try {
                const user = await GoogleAuth.signIn();
                const auth = await httpRequest(
                  `${href}/callback?code=${encodeURIComponent(
                    user.serverAuthCode,
                  )}&scope=${encodeURIComponent(
                    'email profile https://www.googleapis.com/auth/userinfo.email openid https://www.googleapis.com/auth/userinfo.profile',
                  )}`,
                  { headers: { Accept: 'application/json' } },
                );
                const authJson = await auth.json();
                navigate(
                  `/sign-in/with-token-account-select/${authJson.auth_key}`,
                );
              } catch (e) {
                console.error('Error on Google login', e);
              }
            }}
            {...props}
          />
        ) : (
          <ButtonComponent
            isDisabled={!token}
            form={id}
            ref={ref}
            type="submit"
            {...props}
          />
        )}
      </>
    );
  },
);

interface SafeAuthButtonProps extends Omit<AuthButtonProps, 'id'> {
  id: string;
}

export const SafeAuthButton = forwardRef<SafeAuthButtonProps, 'div'>(
  ({ id, ...props }, ref) => {
    if (id in Buttons) {
      return <AuthButton id={id as SupportedButtonType} {...props} ref={ref} />;
    } else {
      return null;
    }
  },
);

export default SafeAuthButton;
