import React, { useCallback, useLayoutEffect } from 'react';
import { useAuthLogic } from '../utils/SignInLogic';
import { useToast } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { SelectPlatform } from '../../redesigned/SelectPlatform';
import { loadTenantDataFor } from '../utils/loadTenantData';
import isAppDomain from 'utils/isAppDomain';

const isMobileApp = isAppDomain();

export const SelectPlatformScreen = () => {
  const toast = useToast();
  const { setScreen } = useAuthLogic();

  useLayoutEffect(() => {
    if (!isMobileApp) {
      setScreen({ screen: 'start' });
    }
  }, []);

  const toastInvalidDomain = useCallback(() => {
    toast({
      title: t`Invalid url`,
      description: t`We could not find a platform with that domain`,
      status: 'error',
      isClosable: true,
      duration: 5000,
    });
  }, [toast]);

  const onConfirmPlatform = useCallback(
    (name: string) =>
      loadTenantDataFor(name)
        .then((tenant) =>
          setScreen({
            screen: 'login-with-password-for-app',
            tenant,
          }),
        )
        .catch(toastInvalidDomain),
    [setScreen, toastInvalidDomain],
  );

  const onForgotPlatform = useCallback(() => {
    setScreen({ screen: 'forgot-platform' });
  }, [setScreen]);

  return (
    <SelectPlatform
      onConfirmPlatform={onConfirmPlatform}
      onForgotPlatform={onForgotPlatform}
    />
  );
};
