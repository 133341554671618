import { useBreakpointValue } from '@cardboard-ui/react';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { Layout, LayoutContext } from './context';

export { Layout };

export const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const undeterminedLayout = useBreakpointValue({
    base: Layout.MOBILE,
    md: Layout.DESKTOP,
  });

  const activeLayout =
    undeterminedLayout === undefined ? Layout.DESKTOP : undeterminedLayout;

  return (
    <LayoutContext.Provider value={{ activeLayout }} children={children} />
  );
};

export const useActiveLayout = () => useContext(LayoutContext).activeLayout;
