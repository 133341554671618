import { RemirrorJSON } from '@remirror/core-types';
import { SettingWithColorModeVariants } from '../types';

export interface PublicScreensSetting {
  readonly background: SettingWithColorModeVariants<PublicScreensBackgroundSettingVariant>;
  readonly contentStyle: SettingWithColorModeVariants<PublicScreensContentStyleSettingVariant>;
  readonly screens?: {
    [screenKey: string]: PublicScreenCustomContent | undefined;
  };
}

export interface PublicScreenCustomContent {
  readonly richContent?: RemirrorJSON;
  readonly base64Image?: string;
}

export interface PublicScreensBackgroundSettingVariant {
  readonly color?: string;
  readonly base64Image?: string;
}

export interface PublicScreensContentStyleSettingVariant {
  readonly backgroundColor: string;
  readonly textColor: string;
}

export const DefaultPublicScreensSetting = {
  background: {
    light: {
      color: '#EDF2F7',
    },
    dark: {
      color: '#1A202C',
    },
  },
  contentStyle: {
    light: {
      backgroundColor: 'gray.600',
      textColor: 'white',
    },
    dark: {
      backgroundColor: 'gray.100',
      textColor: 'black',
    },
  },
};
