import { Button, ButtonProps, forwardRef, Text } from '@cardboard-ui/react';
import { t } from '@lingui/macro';
import { GoogleColoredIcon } from 'components/icons/GoogleColoredIcon';
import React from 'react';

export const GoogleButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      ref={ref}
      leftIcon={<GoogleColoredIcon />}
      backgroundColor="white"
      borderWidth="1px"
      _hover={{ backgroundColor: 'white' }}
      {...props}
    >
      <Text color="black">{t`Sign in with Google`}</Text>
    </Button>
  );
});

export default GoogleButton;
