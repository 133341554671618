/* eslint-disable no-restricted-imports */

import {
  Checkbox as ChakraCheckbox,
  CheckboxProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';
import { useI18n } from 'utils/i18n';

export const Checkbox = forwardRef<CheckboxProps, 'input'>(
  ({ sx, ...props }, ref) => {
    const { direction } = useI18n();
    return (
      <ChakraCheckbox
        ref={ref}
        {...props}
        sx={{
          svg: {
            transform: `scaleX(${direction === 'ltr' ? '1' : '-1'})`,
          },
          ...(sx || {}),
        }}
      />
    );
  },
);
