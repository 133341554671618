type SupportedInputTypes = number | Date | CrdbrdDateObject | string;

export const parseApplicationDate = (
  _date: SupportedInputTypes,
): Date | number => {
  if (typeof _date === 'string') {
    return new Date(_date);
  }
  // Detect CrdbrdDateObject
  if (typeof _date === 'object' && 'utcDate' in _date && 'utcTime' in _date) {
    return new Date(`${_date.utcDate}T${_date.utcTime}Z`);
  }

  return _date;
};

export type CrdbrdDateObject = {
  utcDate: string;
  utcTime: string;
};
