import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';

export const ItemViewerNavigationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [itemIds, setItemIds] = useState<string[]>([]);

  return (
    <ItemViewerNavigationContext.Provider
      value={{
        setItemViewerCollectionIds: setItemIds,
        fetchPreviousId: buildStepFromItemInList({ list: itemIds, step: -1 }),
        fetchNextId: buildStepFromItemInList({ list: itemIds, step: 1 }),
      }}
    >
      {children}
    </ItemViewerNavigationContext.Provider>
  );
};

const mod = (m: number) => (n: number) => ((n % m) + m) % m;

function buildStepFromItemInList<T>({
  list,
  step,
}: {
  list: T[];
  step: number;
}) {
  return (item: T) => {
    const idx = list.indexOf(item);
    if (idx === -1 || list.length === 1) {
      return undefined;
    }
    const listMod = mod(list.length);
    return list[listMod(idx + step)];
  };
}

export const useItemViewerNavigation = () =>
  useContext(ItemViewerNavigationContext);

export type RenameHandler = (newName: string, onComplete: () => void) => void;

interface ItemViewerNavigationContextInterface {
  setItemViewerCollectionIds: (ids: string[]) => void;
  fetchPreviousId: (id: string) => string | undefined;
  fetchNextId: (id: string) => string | undefined;
}

const stub = () => undefined;

const initialContext: ItemViewerNavigationContextInterface = {
  setItemViewerCollectionIds: () => {},
  fetchNextId: stub,
  fetchPreviousId: stub,
};

export const ItemViewerNavigationContext =
  createContext<ItemViewerNavigationContextInterface>(initialContext);
