/* eslint-disable no-restricted-imports */

import {
  MenuList as ChakraMenuList,
  forwardRef,
  MenuListProps,
  Portal,
} from '@chakra-ui/react';
import React from 'react';

export const MenuList = forwardRef<MenuListProps, 'div'>((props, ref) => (
  <Portal appendToParentPortal>
    <ChakraMenuList
      ref={ref}
      {...props}
      zIndex="calc(var(--chakra-zIndices-modal) + 1)"
    />
  </Portal>
));
