import React from 'react';
import { t, Trans } from '@lingui/macro';
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Stack,
  useColorModeValue,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { light } from '@fortawesome/fontawesome-svg-core/import.macro';
import { TrustedFamilyLogo } from './assets/Logo';
import * as EmailValidator from 'email-validator';
import { useSession } from 'utils/sessionProvider';
import { useForm } from 'react-hook-form';

type PasswordlessLoginProps = {
  onClickSignInViaPassword: () => void;
  onLoginWithOnlyMail: (email: string) => void;
  ExternalAuth: React.ComponentType;
};

export const PasswordlessLogin = ({
  onClickSignInViaPassword,
  onLoginWithOnlyMail,
  ExternalAuth,
}: PasswordlessLoginProps) => {
  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      email: '',
    },
  });
  const { tenant } = useSession();

  const onSubmit = handleSubmit(({ email }) => {
    return onLoginWithOnlyMail(email);
  });

  return (
    <AuthenticationScreen returnToStart={false}>
      <Stack spacing={4}>
        <VStack>
          {tenant?.icon?.url ? (
            <Image
              src={tenant.icon.url}
              borderWidth="2px"
              borderRadius="md"
              borderColor="white"
              borderStyle="solid"
              boxSize="50px"
              objectFit="cover"
              textAlign="center"
            />
          ) : (
            <TrustedFamilyLogo />
          )}
          <Heading fontWeight="bold" fontSize="2xl" textAlign="center">
            <Trans>Sign in to {tenant?.name ?? 'Trusted Family'}</Trans>
          </Heading>
        </VStack>
        <form onSubmit={onSubmit}>
          <Input
            {...register('email', {
              required: true,
              validate: {
                email: (value) => EmailValidator.validate(value),
              },
            })}
            type="email"
            placeholder={t`name@your-email.com`}
          />

          <Button
            variant="solid"
            colorScheme="blue"
            isDisabled={!formState.isValid}
            isLoading={formState.isSubmitting}
            type="submit"
            w="full"
            mt={2}
          >{t`Sign in`}</Button>
        </form>

        <VStack>
          <HStack
            bg={useColorModeValue('gray.100', 'gray.700')}
            borderRadius="md"
            p={4}
            spacing={2}
            alignItems="flex-start"
            w="full"
          >
            <FontAwesomeIcon
              icon={light('sparkles')}
              style={{ marginTop: '4px' }}
            />
            <VStack
              alignItems="flex-start"
              color={useColorModeValue('gray.600', 'gray.400')}
              gap={0}
              fontSize="sm"
            >
              <Trans>
                We'll email you an authentication code for a password-free
                sign-in.
              </Trans>
              <Box>
                <Trans>
                  Or you can{' '}
                  <Link
                    to="#"
                    onClick={preventDefault(onClickSignInViaPassword)}
                    fontWeight="bold"
                    textDecoration="underline"
                    fontSize="sm"
                  >
                    sign in with password instead
                  </Link>
                </Trans>
              </Box>
            </VStack>
          </HStack>
          <Box position="relative" paddingY={4} w="full">
            <Divider />
            <AbsoluteCenter
              px={4}
              bg={useColorModeValue('white', 'gray.700')}
              color="gray.500"
              fontSize="sm"
            >{t`OR`}</AbsoluteCenter>
          </Box>
          <ExternalAuth />
        </VStack>
      </Stack>
    </AuthenticationScreen>
  );
};

const preventDefault =
  (callback: (event: React.MouseEvent) => unknown) =>
  (event: React.MouseEvent) => {
    event.preventDefault();
    callback(event);
  };
