import { extendTheme } from '@cardboard-ui/react';
import { CUSTOM_THEME } from 'theme';
import { DesktopMenu } from './components/DesktopMenu';
import { TextLink } from './components/TextLink';

export default extendTheme(CUSTOM_THEME, {
  components: {
    DesktopMenu,
    TextLink,
  },
});
