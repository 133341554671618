import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ThumbnailRefreshContext } from './context';
import throttle from 'lodash/throttle';
import difference from 'lodash/difference';
import { fetchQuery, graphql, useRelayEnvironment } from 'utils/graphClient';
import { notEmpty } from 'utils/NotEmptyFilter';
import { ThumbnailRefreshProvider_polling_Query } from './__generated__/ThumbnailRefreshProvider_polling_Query.graphql';

const MAX_THUMBNAIL_RETRIES = 10;

const query = graphql`
  query ThumbnailRefreshProvider_polling_Query($ids: [ID!]!) {
    nodes(ids: $ids) {
      id
      ... on HasImageInterface {
        thumbnail: image(width: 750) {
          url
        }
      }
    }
  }
`;

const ThumbnailRefreshProvider: FC<PropsWithChildren> = ({ children }) => {
  // We keep track of the nodes we need to refresh, with "how many times" we attempted to fetch their thumbnails
  const [thumbnailPoolIds, setThumbnailPoolIds] = useState<{
    [key: string]: number;
  }>({});
  // We use a value `refreshKey` to decide when/how to update
  const [refreshKey, _setRefreshKey] = useState<number>();
  // We throttle setting this value, this way we always have a bit of delay
  // Using throttle also prevents continuous delays while updates complete
  const updateFetchKey = useCallback(
    throttle(() => {
      _setRefreshKey(Date.now());
    }, 5000),
    [_setRefreshKey],
  );
  // Once the value is actually set, we can do the actual update
  useEffect(
    () => updateThumbnails(Object.keys(thumbnailPoolIds)),
    [refreshKey],
  );

  // For our external hooks, we should allow easy adding of new nodes to poll
  const addNewThumbnailNode = useCallback(
    (folderNodeId: string) => {
      setThumbnailPoolIds((thumbnailPoolIds) => ({
        ...thumbnailPoolIds,
        [folderNodeId]: 0,
      }));
      // If a new node is added, we will update
      updateFetchKey();
    },
    [setThumbnailPoolIds],
  );

  const environment = useRelayEnvironment();
  const updateThumbnails = useCallback(
    (ids: string[]) => {
      // No need to update, if there is nothing to update
      if (ids.length === 0) {
        return;
      }

      // Using fetch query will update the relay store
      //   but won't directly affect the current component directly
      fetchQuery<ThumbnailRefreshProvider_polling_Query>(
        environment,
        query,
        {
          ids,
        },
        { fetchPolicy: 'network-only' },
      ).subscribe({
        next: (data) => {
          // Once the data is resolved, we need to updated the internal state
          const idsResolved = data.nodes
            .filter(notEmpty)
            .filter((node) => node.thumbnail?.url)
            .map((node) => node.id);
          // if a node is not returned, we should also consider that a miss
          const idsMissed = difference(ids, idsResolved);

          setThumbnailPoolIds((currentThumbnailPoolIds) => {
            // Lets rebuild the currentIds
            const newThumbnailsPoolIds = {} as { [key: string]: number };
            Object.keys(currentThumbnailPoolIds).forEach((id) => {
              const resolved = idsResolved.includes(id);
              const missed = idsMissed.includes(id);
              const timedOut =
                missed && currentThumbnailPoolIds[id] >= MAX_THUMBNAIL_RETRIES;

              if (resolved || timedOut) {
                // if the current nodeId is resolved, or timed out
                //   it will not be added to the state anymore
              } else if (missed) {
                // If we missed the thumbnail, increase the counter
                newThumbnailsPoolIds[id] = currentThumbnailPoolIds[id] + 1;
              } else {
                // We did not poll for this value, so keep it as it is
                newThumbnailsPoolIds[id] = currentThumbnailPoolIds[id];
              }
            });

            // Return the new
            return newThumbnailsPoolIds;
          });
          // Get ready to fetch again
          updateFetchKey();
        },
        error: () => {},
      });
    },
    [setThumbnailPoolIds, updateFetchKey],
  );

  return (
    <ThumbnailRefreshContext.Provider value={{ addNewThumbnailNode }}>
      {children}
    </ThumbnailRefreshContext.Provider>
  );
};

export const useThumbnailRefresh = () => useContext(ThumbnailRefreshContext);
export default ThumbnailRefreshProvider;
