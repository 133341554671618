import { CenteredSpinner } from 'components/CenteredSpinner';
import React, { PropsWithChildren } from 'react';
import { Suspense } from 'react';

export const SuspendWithSpinner: React.FC<
  PropsWithChildren<{ debugName?: String }>
> = ({ children, debugName }) => (
  <Suspense
    fallback={
      <CenteredSpinner
        debugName={`SuspendWithSpinner${debugName ? `/${debugName}` : ''}`}
      />
    }
  >
    {children}
  </Suspense>
);
