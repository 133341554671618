import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider as LinguiI18nProvider } from '@lingui/react';
import { CenteredSpinner } from 'components/CenteredSpinner';

// Make sure to load @remirror/i18n first
// As that file invokes `i18n.activate('en')
// Our remirror is loaded async, and would "reset" the
//   active locale on load
import '@remirror/i18n';
import { useInitialLanguage } from './useInitialLanguage';
import { usePersistMemberLanguageCode } from './usePersistMemberLanguageCode';
import { buildFormat, FormatFn } from './buildFormat';
import { buildFormatDistance, FormatDistanceFn } from './buildFormatDistance';
import { useDeviceLanguagePreference } from './useDeviceLanguagePreference';
import { useSession } from 'utils/sessionProvider';
import { subscribeDevice } from 'utils/push';
import { buildFormatTimezone, FormatTimezoneFn } from './buildFormatTimezone';

export const AVAILABLE_LOCALES = [
  'en',
  'en-GB',
  'fr',
  'ar',
  'de',
  'nl',
  'pt',
  'pt-BR',
  'es',
  'it',
] as const;
export type AvailableLocale = typeof AVAILABLE_LOCALES[number];
export type Direction = 'ltr' | 'rtl';
export const localeDirection = (locale: AvailableLocale): Direction =>
  locale === 'ar' ? 'rtl' : 'ltr';
export const DEFAULT_LOCALE = 'en' as AvailableLocale;
export const DEFAULT_DIRECTION = localeDirection(DEFAULT_LOCALE);

const Monday = 1 as const;
const Sunday = 0 as const;

type I18nContextType = {
  availableLocales: typeof AVAILABLE_LOCALES;
  locale: AvailableLocale;
  accountLocale: AvailableLocale | null;
  direction: Direction;
  format: FormatFn;
  formatDistance: FormatDistanceFn;
  formatTimeZone: FormatTimezoneFn;
  setLocale: (locale: AvailableLocale) => void;
  firstDayOfWeek: typeof Monday | typeof Sunday;
};

const initialI18nContext = {
  availableLocales: AVAILABLE_LOCALES,
  locale: DEFAULT_LOCALE,
  accountLocale: null,
  direction: DEFAULT_DIRECTION,
  format: () => '',
  formatDistance: () => '',
  formatTimeZone: () => '',
  setLocale: () => {},
  firstDayOfWeek: Monday,
};

export const I18nContext = createContext<I18nContextType>(initialI18nContext);

export const I18nProvider = ({ children }: PropsWithChildren) => {
  const persistLanguageCode = usePersistMemberLanguageCode();
  const [locale, _setLocale] = useState<AvailableLocale | undefined>(undefined);
  const getInitialLanguage = useInitialLanguage();
  const [, setDeviceLanguagePreference] = useDeviceLanguagePreference();
  const { member } = useSession();
  const accountLocale = member?.languageCode
    ? (member.languageCode as AvailableLocale)
    : null;

  useEffect(() => {
    // This is how you do async functions in useEffect hooks
    const setLocaleNow = async () => {
      const defaultLocale = await getInitialLanguage();
      await activateLocale(defaultLocale);
      _setLocale(defaultLocale);
    };
    setLocaleNow();
  }, []);

  const setLocale = async (locale: AvailableLocale) => {
    setDeviceLanguagePreference(false);
    await persistLanguageCode(locale);
    await activateLocale(locale);
    _setLocale(locale);
    subscribeDevice(); //Fire and forget, to update the language for push notifications
  };

  if (locale) {
    const contextValue = {
      availableLocales: AVAILABLE_LOCALES,
      locale,
      accountLocale,
      direction: localeDirection(locale),
      format: buildFormat(locale),
      formatTimeZone: buildFormatTimezone(locale),
      formatDistance: buildFormatDistance(locale),
      setLocale,
      firstDayOfWeek: Monday,
    };
    return (
      <I18nContext.Provider value={contextValue} key={locale}>
        <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>
      </I18nContext.Provider>
    );
  } else {
    return <CenteredSpinner debugName="Loading locale" />;
  }
};

export const DemoI18nProvider = ({
  children,
  locale: desiredLocale,
}: PropsWithChildren<{ locale: AvailableLocale }>) => {
  const [locale, _setLocale] = useState<AvailableLocale | undefined>(undefined);

  useEffect(() => {
    const updateLocale = async () => {
      await activateLinguiLocale(desiredLocale);
      _setLocale(desiredLocale);
    };
    updateLocale();
  }, [desiredLocale]);

  if (locale) {
    const contextValue = {
      availableLocales: AVAILABLE_LOCALES,
      locale,
      accountLocale: locale,
      direction: localeDirection(locale),
      format: buildFormat(locale),
      formatTimeZone: buildFormatTimezone(locale),
      formatDistance: buildFormatDistance(locale),
      setLocale: () => {},
      firstDayOfWeek: Monday,
    };

    return (
      <I18nContext.Provider value={contextValue} key={locale}>
        <LinguiI18nProvider i18n={i18n}>{children}</LinguiI18nProvider>
      </I18nContext.Provider>
    );
  } else {
    return <CenteredSpinner debugName="Loading locale" />;
  }
};

export const useI18n = () => useContext(I18nContext);

declare global {
  interface Window {
    __debugLanguages: () => void;
  }
}

// Old code, still in use

const activateLocale = async (locale: AvailableLocale) => {
  await activateLinguiLocale(locale);
  updateDom({
    locale,
    dir: localeDirection(locale),
  });
};

const activateLinguiLocale = (locale: AvailableLocale) => {
  const verifiedLocale = AVAILABLE_LOCALES.includes(locale)
    ? locale
    : DEFAULT_LOCALE;

  console.debug('activateLocale', { locale, verifiedLocale });

  return import(`@lingui/loader!locales/${verifiedLocale}/messages.po`)
    .then(({ messages }) => {
      i18n.load(locale, messages);
      i18n.activate(locale);
    })
    .catch((err) => {
      console.error(err);
    });
};

const updateDom = ({
  locale,
  dir,
}: {
  locale: AvailableLocale;
  dir: Direction;
}) => {
  window.document.body.lang = locale;
  window.document.body.dir = dir;
};

declare global {
  interface Window {
    __localeId__?: string;
  }
}
