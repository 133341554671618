import React from 'react';
import { t, Trans } from '@lingui/macro';
import {
  Button,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  VStack,
} from '@cardboard-ui/react';
import { AuthenticationScreen } from './components/AuthenticationScreen';
import { TrustedFamilyLogo } from './assets/Logo';
import { useForm } from 'react-hook-form';

type SelectPlatformProps = {
  onConfirmPlatform: (tenantName: string) => Promise<void>;
  onForgotPlatform: () => void;
};

export const SelectPlatform = ({
  onConfirmPlatform,
  onForgotPlatform,
}: SelectPlatformProps) => {
  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      subdomain: '',
    },
  });
  const onSubmit = handleSubmit(({ subdomain }) => {
    return onConfirmPlatform(subdomain);
  });

  return (
    <AuthenticationScreen>
      <Stack spacing={4} as="form" onSubmit={onSubmit}>
        <VStack>
          <TrustedFamilyLogo />
          <Heading
            fontWeight="bold"
            fontSize="2xl"
          >{t`Sign in to your platform`}</Heading>
          <Text color="gray.500">
            <Trans>Enter your Platform's Trusted Family URL</Trans>
          </Text>
        </VStack>
        <InputGroup alignItems="baseline">
          <Input
            type="text"
            {...register('subdomain', { required: true, minLength: 3 })}
            autoComplete="off"
            placeholder={t`your-platform`}
            autoFocus
            autoCapitalize="none"
          />
          <InputRightAddon>
            <strong>.trustedfamily.com</strong>
          </InputRightAddon>
        </InputGroup>

        <Button
          variant="solid"
          colorScheme="blue"
          type="submit"
          isDisabled={!formState.isValid}
          isLoading={formState.isSubmitting}
        >{t`Continue`}</Button>

        <HStack
          wrap="wrap"
          justifyContent="center"
          color="gray.500"
          fontSize="sm"
        >
          <Text>{t`Don't know your platform's URL?`}</Text>
          <Button
            onClick={onForgotPlatform}
            variant="link"
            fontWeight="bold"
            textDecoration="underline"
          >{t`Find your platform`}</Button>
        </HStack>
      </Stack>
    </AuthenticationScreen>
  );
};
